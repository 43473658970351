<template>
  <div class="footer">
    <div class="w12">
      <div class="menu">
        <div class="menuTitle">
          新葡京
        </div>
        <div class="menuItemBox">
          <div class="menuItem" v-for="(item,index) in menuList" @click="selectIndex(index,item.path)" :key="index">
            {{item.title}}
          </div>
        </div>
      </div>
      <div class="menu liuMargin">
        <div class="menuTitle">
          支持浏览器
        </div>
        <div class="liuBox">
          <img src="@/assets/images/home/foot1.png" alt="">
        </div>
      </div>
      <div class="menu jianMargin">
        <div class="menuTitle">
          监管机构
        </div>
        <div class="jianBox">
          <img src="@/assets/images/home/foot2.png" alt="">
        </div>
      </div>
      <div class="menu heMargin">
        <div class="menuTitle">
          合作伙伴
        </div>
        <div class="heBox">
          <img src="@/assets/images/home/footer_bg.png" alt="">
        </div>
      </div>
    </div>
    <div class="footerBottom flex_center">版权为新葡京 所有@ 2023</div>
  </div>
</template>

<script>
export default {
  name: "footerIndex",
  data(){
    return{
      menuList:[
        {title:'关于我们',path:'/about'},
        {title:'联系我们',path:'/about'},
        {title:'存款帮助',path:'/about'},
        {title:'取款帮助',path:'/about'},
        {title:'合作伙伴',path:'/about'}
      ],

    }
  },
  methods:{
    selectIndex(index,path){
        if(this.$route.path !== path){
          this.$router.push({path:path})
        }
        this.$emit('son',index)
    }
  }
}
</script>

<style scoped lang="scss">
.footer{
  width: 100%;
  height: 311px;
  min-width: 1200px;
  background: url("@/assets/images/home/footBg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 52px;
  .w12{
    display: flex;
    align-items: center;
  }
  .menu{
    .menuTitle{
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
      margin-bottom: 21px;
    }
    .liuBox{
      width: 102px;
      height: 137px;
    }
    .jianBox{
      width: 62px;
      height: 134px;
    }
    .heBox{
      width: 653px;
      height: 134px;
    }
    .menuItemBox{
      padding-top: 5px;
      margin-left: 7px;
    }
    .menuItem{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      margin-bottom: 8px;
    }
  }
  .liuMargin{
    margin-left: 117px;
  }
  .jianMargin{
    margin-left: 78px;
  }
  .heMargin{
    margin-left: 109px;
  }
  .footerBottom{
    width: 100%;
    height: 50px;
    background: rgba(0,0,0,0.15);
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
