<template>
  <div class="headBox">
    <div class="w12" v-if="pageIndex == 1">
      <div class="headLeft">
        <img src="@/assets/images/home/zh.png" alt="" />
        <span class="marginSpan">中文</span>
        <!-- <span>GMT+8 2024-08-29 10:</span>
          -->
      </div>
      <div class="headRight" v-if="userInfo && userInfo.username">
        <img src="@/assets/images/home/v0.png" alt="" />
        <div class="username">
          {{ $t("home.账号") }}: {{ userInfo.username }}
        </div>
        <div class="yue">{{ $t("home.余额") }}(RMB) {{ userInfo.coin }}</div>
        <div class="shua flex_center" @click="shua">
          {{ $t("home.一键刷新") }}
        </div>
        <div class="member" @click="showModelInfo">{{ $t("home.vip") }}</div>
        <div class="member member_margin" @click="centerModel = true">
          {{ $t("home.info") }}
        </div>
        <div class="logout flex_center" @click="outLogin">
          {{ $t("home.登出") }}
        </div>
      </div>
    </div>
    <div class="w1200" v-if="pageIndex == 2">
      <div class="lotteryLeft">
        <img src="@/assets/images/home/logo.png" alt="" class="logo" />
        <div class="zhang">{{ $t("home.账号") }}: {{ userInfo.username }}</div>
        <div class="zhang" style="margin-left: 15px">
          {{ $t("home.可用余额") }}:
          <span class="red">{{ userInfo.coin }}</span>
        </div>
        <div class="btnList">
          <div
            class="btnItem"
            v-for="(item, index) in btnList"
            @click="showModelfun(item)"
            :key="index"
          >
            {{ item.title }}
            <div class="shu" v-if="index < 4"></div>
          </div>
        </div>
      </div>
      <div class="lotteryRight">
        <div class="rightItem">
          <img src="@/assets/images/lottery/hot.png" alt="" class="img0" />
          {{ $t("tabs.热门彩种") }}
          <div class="shu"></div>
        </div>
        <div class="rightItem">
          <img src="@/assets/images/lottery/zou.png" alt="" class="img1" />
          {{ $t("tabs.开奖走势") }}
          <div class="shu"></div>
        </div>
        <div class="rightItem">
          <img src="@/assets/images/lottery/gui.png" alt="" class="img0" />
          {{ $t("tabs.玩法规则") }}
          <div class="shu"></div>
        </div>
        <div class="rightItem">
          <img src="@/assets/images/lottery/an.png" alt="" class="img2" />
          <img src="@/assets/images/lottery/ios.png" alt="" class="img2" />
          {{ $t("tabs.手机APP下载") }}
        </div>
      </div>
    </div>
    <van-popup v-model="showModel" round>
      <div class="modelBox">
        <div class="modelHead">
          {{ $t("home.xiaoxi") }}
          <img
            src="../assets/images/model/close.png"
            @click="showModel = false"
            alt=""
          />
        </div>
        <div class="modelCont">
          <img src="../assets/images/model/gan.png" alt="" />{{
            $t("home.shua")
          }}
        </div>
      </div>
    </van-popup>
    <van-popup v-model="centerModel" round @close="chongzhi">
      <div class="modelMain">
        <div class="leftMenu">
          <div class="menuBox">
            <div
              class="menuItem"
              :class="{ active: menuIndex == index }"
              @click="levelSelect(index)"
              v-for="(item, index) in menuList"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <div class="itemTitle">{{ item.title }}</div>
            </div>
          </div>
          <div class="menuBg"></div>
          <div class="menuMain">
            <div class="czBox" v-if="menuIndex == 0">
              <div class="cTitle">{{ $t("home.充值") }}</div>
              <div class="cYue">
                {{ $t("home.余额") }}:{{ userInfo.coin }}
                <img
                  src="../assets/images/model/shua.png"
                  alt=""
                  @click="getNew"
                />
              </div>
              <div class="cDai flex_center">
                {{ $t("home.代理佣金") }}:0.00
                <img src="../assets/images/model/deng.png" alt="" />
              </div>
              <div class="czList">
                <div
                  class="czItem flex_center"
                  :class="{ czActive: czIndex == t }"
                  @click="czIndex = t"
                  v-for="(i, t) in czList"
                  :key="t"
                >
                  {{ i }}
                  <div class="zeng" v-if="t < czList.length - 3">
                    {{ $t("home.赠送") }}2.5%
                  </div>
                </div>
              </div>
            </div>
            <div class="czBox" v-if="menuIndex == 1">
              <div class="cTitle">{{ $t("home.提款") }}</div>
              <div class="cYue">
                {{ $t("home.余额") }}:{{ userInfo.coin }}
                <img
                  src="../assets/images/model/shua.png"
                  alt=""
                  @click="getNew"
                />
              </div>
              <div class="cDai flex_center">
                {{ $t("home.代理佣金") }}:0.00
                <img src="../assets/images/model/deng.png" alt="" />
              </div>
              <div class="czList">
                <div
                  class="czItem flex_center"
                  :class="{ czActive: tkIndex == t }"
                  @click="tikuan2tab(t)"
                  v-for="(i, t) in tkList"
                  :key="t"
                >
                  {{ i }}
                </div>
              </div>
            </div>
            <div class="czBox" v-if="menuIndex == 2">
              <div class="cTitle">{{ $t("home.提款") }}</div>
              <div class="cYue">
                {{ $t("home.余额") }}:{{ userInfo.coin }}
                <img
                  src="../assets/images/model/shua.png"
                  alt=""
                  @click="getNew"
                />
              </div>
              <div class="cDai flex_center">
                {{ $t("home.代理佣金") }}:0.00
                <img src="../assets/images/model/deng.png" alt="" />
              </div>
              <div class="czList">
                <div class="czItem flex_center czActive">转账中心</div>
              </div>
            </div>
            <div class="czBox" v-if="menuIndex == 3">
              <div class="cTitle">{{ $t("home.提款") }}</div>
              <div class="cYue">
                {{ $t("home.余额") }}:{{ userInfo.coin }}
                <img
                  src="../assets/images/model/shua.png"
                  alt=""
                  @click="getNew"
                />
              </div>
              <div class="cDai flex_center">
                {{ $t("home.代理佣金") }}:{{ userInfo.coin }}
                <img src="../assets/images/model/deng.png" alt="" />
              </div>
              <div class="czList">
                <div
                  class="czItem flex_center"
                  :class="{ czActive: mineIndex == t }"
                  @click="gerentab(t)"
                  v-for="(i, t) in mineList"
                  :key="t"
                >
                  {{ i }}
                </div>
              </div>
            </div>
            <div class="czBox" v-if="menuIndex == 5">
              <div class="cTitle">{{ $t("home.提款") }}</div>
              <div class="cYue">
                {{ $t("home.余额") }}:{{ userInfo.coin }}
                <img
                  src="../assets/images/model/shua.png"
                  alt=""
                  @click="getNew"
                />
              </div>
              <div class="cDai flex_center">
                {{ $t("home.代理佣金") }}:0.00
                <img src="../assets/images/model/deng.png" alt="" />
              </div>
              <div class="czList">
                <div
                  class="czItem flex_center"
                  :class="{ czActive: daiIndex == t }"
                  @click="daiIndex = t"
                  v-for="(i, t) in daiList"
                  :key="t"
                >
                  {{ i }}
                </div>
              </div>
            </div>
            <div class="czBox" v-if="menuIndex == 6">
              <div class="cTitle">{{ $t("home.提款") }}</div>
              <div class="cYue">
                {{ $t("home.余额") }}:{{ userInfo.coin }}
                <img
                  src="../assets/images/model/shua.png"
                  alt=""
                  @click="getNew"
                />
              </div>
              <div class="cDai flex_center">
                {{ $t("home.代理佣金") }}:0.00
                <img src="../assets/images/model/deng.png" alt="" />
              </div>
              <div class="czList">
                <div
                  class="czItem flex_center"
                  :class="{ czActive: systemIndex == t }"
                  @click="msgClick(t)"
                  v-for="(i, t) in systemList"
                  :key="t"
                >
                  {{ i }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <!--网银充值-->
          <div class="czRight" v-if="menuIndex == 0 && czIndex == 0">
            <div class="czRightTitle">
              <div class="left">
                <img src="../assets/images/model/wang.png" alt="" />
                {{ $t("home.网银转账") }}
              </div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czMainBox">
              <div class="czItemLeft">
                <div class="cunNameBox">
                  <div class="cunTitle">{{ $t("home.存款姓名") }}：</div>
                  <input autocomplete="new-password" type="text" />
                </div>
                <div class="bao">
                  <img src="../assets/images/model/gou.png" alt="" />{{
                    $t("home.保存姓名")
                  }}
                </div>
                <div class="cunNameBox">
                  <div class="cunTitle">{{ $t("home.存款金额") }}:</div>
                  <input
                    autocomplete="new-password"
                    type="number"
                    :placeholder="$t('home.单笔存款金额') + '：300～50000'"
                    placeholder-class="place"
                  />
                </div>
                <div class="cunMoneyBox">
                  <el-radio v-model="radio" label="1">300</el-radio>
                  <el-radio v-model="radio" label="2">1000</el-radio>
                  <el-radio v-model="radio" label="3">10000</el-radio>
                </div>
                <div class="cunBtn flex_center" @click="cunkuan">
                  {{ $t("home.立即存款") }}
                </div>
              </div>
              <div class="czItemRight">
                <div class="title">{{ $t("home.存款信息") }}</div>
                <div class="cunBox">
                  <div class="cunHead">
                    <div class="headTitle">300{{ $t("home.yuan") }}</div>
                    <div class="headTips">（{{ $t("home.yizhi") }}）</div>
                  </div>
                  <div class="cunBg">
                    <div class="cunTitle">
                      {{ $t("home.tuijian") }}
                      <img src="../assets/images/model/copy.png" alt="" />
                    </div>
                    <div class="cunTitle cunMoney">
                      8888888888
                      <img src="../assets/images/model/copy.png" alt="" />
                    </div>
                    <div class="cunTitle cunName">
                      {{ $t("home.取款人") }}：{{ $t("home.tuijian") }}
                      <img src="../assets/images/model/copy.png" alt="" />
                    </div>
                  </div>
                  <div class="upLoadBox">
                    <div class="left">
                      <div class="upLoadTitle">
                        {{ $t("home.上传支付凭证") }}(0/2):
                      </div>
                      <div class="tips">{{ $t("home.shangchuan") }}</div>
                    </div>
                    <div class="right"></div>
                  </div>
                </div>
                <div class="cunBtnBox">
                  <div class="cunBtnItem flex_center">
                    {{ $t("home.取消存款申请") }}
                  </div>
                  <div class="cunBtnItem flex_center">
                    {{ $t("home.我已存款") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 0 && czIndex == 1">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.U币支付") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="uPayBox">
              <div class="payTitle">
                <img
                  src="../assets/images/model/uIcon.png"
                  class="uIcon"
                  alt=""
                />
                <div class="payYue">
                  {{ $t("home.U币余额") }}：<span
                    >{{ $t("home.danwei") }}0.00</span
                  >
                </div>
                <img
                  src="../assets/images/model/ushua.png"
                  class="shua"
                  alt=""
                />
                <div class="buyUBtn flex_center">
                  + {{ $t("home.购买U币") }}
                </div>
              </div>
              <div class="payItem" style="margin-top: 33px">
                <div class="pay_left" style="padding-top: 7px">
                  {{ $t("home.存款金额") }}：
                </div>
                <div class="pay_right">
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.单笔存款金额') + '：300～50000'"
                    placeholder-class="place"
                  />
                  <div class="cunMoneyBox">
                    <el-radio v-model="radio1" label="1">300</el-radio>
                    <el-radio v-model="radio1" label="2">1000</el-radio>
                    <el-radio v-model="radio1" label="3">10000</el-radio>
                  </div>
                </div>
              </div>
              <div class="payItem" style="margin-top: 15px">
                <div class="pay_left">{{ $t("home.温馨提示") }}：</div>
                <div class="pay_right">
                  <div class="tips">
                    1、{{ $t("home.dengzhi") }}
                    <br />
                    {{ $t("home.info1") }}<br />
                    2、{{ $t("home.info2") }}
                    <br />
                    {{ $t("home.info3") }}<span>{{ $t("home.info4") }}</span
                    ><br />
                    3、{{ $t("home.info5") }}
                  </div>
                  <div class="u_cunBtn flex_center">
                    {{ $t("home.立即存款") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 0 && czIndex == 2">
            <div class="czRightTitle">
              <div class="left">
                {{ $t("home.K豆钱包") }}
                <div class="search flex_center">{{ $t("home.通道一") }}</div>
              </div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="uPayBox">
              <div class="payItem" style="margin-top: 33px">
                <div class="pay_left" style="padding-top: 7px">
                  {{ $t("home.存款金额") }}：
                </div>
                <div class="pay_right">
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.单笔存款金额') + '：300～50000'"
                    placeholder-class="place"
                  />
                  <div class="cunMoneyBox">
                    <el-radio v-model="radio2" label="1">300</el-radio>
                    <el-radio v-model="radio2" label="2">1000</el-radio>
                    <el-radio v-model="radio2" label="3">10000</el-radio>
                  </div>
                </div>
              </div>
              <div class="payItem" style="margin-top: 15px">
                <div class="pay_left">{{ $t("home.温馨提示") }}：</div>
                <div class="pay_right">
                  <div class="tips">
                    1、{{ $t("home.dengzhi") }}
                    <br />
                    {{ $t("home.info1") }}<br />
                    2、{{ $t("home.info2") }}
                    <br />
                    {{ $t("home.info3") }}<span>{{ $t("home.info4") }}</span
                    ><br />
                    3、{{ $t("home.info5") }}
                  </div>
                  <div
                    class="u_cunBtn flex_center"
                    @click="showRechange = true"
                  >
                    {{ $t("home.立即存款") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0"
            v-if="
              (menuIndex == 1 && tkIndex == 0) ||
              (menuIndex == 3 && mineIndex == 4)
            "
          >
            <div class="czRightTitle">
              <div class="leftBox" style="align-items: center">
                <el-radio
                  v-model="radio3"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.今日") }}</el-radio
                >
                <el-radio v-model="radio3" label="2">{{
                  $t("home.昨天")
                }}</el-radio>
                <el-radio v-model="radio3" label="3">{{
                  $t("home.最近七天")
                }}</el-radio>
                <div class="timePickBox">
                  <el-date-picker
                    v-model="value1"
                    type="date"
                    :placeholder="$t('home.选择时间')"
                  >
                  </el-date-picker>
                </div>
                <div class="search flex_center">{{ $t("home.搜索") }}</div>
              </div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="tkMain">
              <el-table :data="tixianList" style="width: 100%">
                <el-table-column
                  prop="id"
                  :label="$t('home.提款编号')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  width="180"
                  prop="name"
                  :label="$t('home.dizhi')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="count"
                  :label="$t('home.提款金额')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  width="180"
                  prop="created_at"
                  :label="$t('home.提款时间')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="cash_status"
                  :label="$t('home.状态')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="remark"
                  :label="$t('home.备注')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('home.催账')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0 0 0 29px"
            v-if="menuIndex == 1 && tkIndex == 2"
          >
            <div class="czRightTitle">
              <div class="left">{{ $t("home.钱包取款") }}</div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="walletBox">
              <div class="walletLeft">
                <div class="walletBanner">
                  <div class="title">{{ $t("home.jiujiu") }}</div>
                  <div class="name">{{ bankInfos.code }}</div>
                  <div class="bannerFoot">
                    <div class="left">
                      {{ $t("home.取款人") }}：{{ bankInfos.name }}
                    </div>
                    <div class="right">
                      <span></span> {{ $t("home.绑定时间") }}：{{
                        bankInfos.time
                      }}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="title" style="flex: none">
                    {{ $t("home.提款金额") }}：
                  </div>
                  <el-input
                    autocomplete="new-password"
                    v-model="qianbaomoney"
                    :placeholder="
                      $t('home.可提现金额') + userInfo.coin + $t('home.yuan')
                    "
                  ></el-input>
                  <div
                    class="allText"
                    style="flex: none"
                    @click="qianbaomoney = userInfo.coin"
                  >
                    {{ $t("home.全额提款") }}
                  </div>
                </div>
                <div class="item" style="margin-top: 10px">
                  <div class="title" style="flex: none">
                    {{ $t("home.资金密码") }}：
                  </div>
                  <el-input
                    autocomplete="new-password"
                    v-model="zijinmima"
                    show-password
                    :placeholder="$t('home.请输入6位资金密码')"
                  ></el-input>
                  <div class="allText" style="color: transparent">
                    {{ $t("home.全额提款") }}
                  </div>
                </div>
                <div class="itemTips">
                  {{ $t("home.zhuyi") }}
                </div>
                <div class="submitBtn flex_center" @click="tixian">
                  {{ $t("home.立即取款") }}
                </div>
              </div>
              <div class="walletRight">
                <div class="walletRightHead">
                  <div class="item flex_center">{{ $t("home.钱包") }}</div>
                  <div class="item flex_center">{{ $t("home.地址") }}</div>
                  <div class="w30 flex_center">{{ $t("home.提款金额") }}</div>
                  <div class="w30 flex_center">{{ $t("home.提款时间") }}</div>
                  <div class="item flex_center">{{ $t("home.状态") }}</div>
                </div>
                <div class="walletRightMain">
                  <img src="../assets/images/model/noData.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0 0 0 29px"
            v-if="menuIndex == 1 && tkIndex == 5"
          >
            <div class="czRightTitle">
              <div class="left">{{ $t("home.绑定银行卡") }}</div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="walletBox" v-loading="loading">
              <div class="walletLeft" style="padding-top: 32px">
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.提款姓名") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    v-model="username"
                    placeholder-class="place"
                  />
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.开户银行") }}：</div>
                  <div class="cpTimeBox">
                    <el-select
                      v-model="khvalue"
                      :placeholder="$t('home.请选择')"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.银行卡号") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    placeholder=""
                    placeholder-class="place"
                    v-model="banckNum"
                  />
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.开户网点") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    placeholder=""
                    placeholder-class="place"
                    v-model="banckaddress"
                  />
                  <!-- <div class="cpTimeBox" style="width: 107px">
                    <el-select
                      v-model="khvalue1"
                      :placeholder="$t('home.请选择')"
                    >
                      <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div> -->
                  <!-- <div class="cpTimeBox" style="width: 107px; margin-left: 8px">
                    <el-select
                      v-model="khvalue2"
                      :placeholder="$t('home.请选择')"
                    >
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div> -->
                </div>
                <div v-if="banckInfo.length == 0">
                  <div class="item" style="margin-bottom: 16px">
                    <div class="title" style="flex: none">
                      {{ $t("home.资金密码") }}：
                    </div>
                    <el-input
                      autocomplete="new-password"
                      placeholder=""
                      v-model="zijinmima"
                      show-password
                    ></el-input>
                  </div>
                  <div class="item" style="margin-bottom: 16px">
                    <div class="title" style="flex: none">
                      {{ $t("home.确认密码") }}：
                    </div>
                    <el-input
                      autocomplete="new-password"
                      placeholder=""
                      v-model="password_confirmation"
                      show-password
                    ></el-input>
                  </div>
                  <div
                    class="submitBtn flex_center"
                    style="margin-left: 0"
                    @click="bandBank"
                  >
                    {{ $t("home.确认提交") }}
                  </div>
                </div>
              </div>
              <div class="walletRight">
                <div class="walletRightMain">
                  <img src="../assets/images/model/noData.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0 0 0 29px"
            v-if="menuIndex == 1 && tkIndex == 6"
          >
            <div class="czRightTitle">
              <div class="left">{{ $t("home.绑定USDT") }}</div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="walletBox">
              <div class="walletLeft" style="padding-top: 32px">
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.提款姓名") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    v-model="username"
                    placeholder-class="place"
                  />
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.选择币种") }}：</div>
                  <div class="cpTimeBox">
                    <el-select
                      v-model="khvalue3"
                      :placeholder="$t('home.请选择')"
                    >
                      <el-option
                        v-for="item in options3"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.USDT地址") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.请输入您的USDT收款地址')"
                    placeholder-class="place"
                  />
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.资金密码") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.请输入6位资金密码')"
                    placeholder-class="place"
                  />
                </div>
                <div class="submitBtn flex_center" style="margin-left: 0">
                  {{ $t("home.确认提交") }}
                </div>
              </div>
              <div class="walletRight">
                <div class="walletRightMain">
                  <img src="../assets/images/model/noData.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0 0 0 29px"
            v-if="menuIndex == 1 && tkIndex == 7"
          >
            <div class="czRightTitle">
              <div class="left">{{ $t("home.绑定钱包") }}</div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="walletBox">
              <div class="walletLeft" style="padding-top: 32px">
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.绑定三方") }}：</div>
                  <div class="cpTimeBox">
                    <el-select
                      v-model="khvalue4"
                      :placeholder="$t('home.请选择')"
                    >
                      <el-option
                        v-for="item in options4"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.提款姓名") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    v-model="username"
                    placeholder-class="place"
                  />
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">
                    <span style="color: transparent">{{ $t("home.地址") }}</span
                    >{{ $t("home.地址") }}：
                  </div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.请输入您的收款地址')"
                    placeholder-class="place"
                  />
                </div>
                <div class="item" style="margin-bottom: 16px">
                  <div class="title">{{ $t("home.资金密码") }}：</div>
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.请输入6位资金密码')"
                    placeholder-class="place"
                  />
                </div>
                <div class="submitBtn flex_center" style="margin-left: 0">
                  {{ $t("home.确认提交") }}
                </div>
              </div>
              <div class="walletRight">
                <div class="walletRightMain" style="align-items: unset">
                  <div class="walletBanner">
                    <div class="title">{{ $t("home.jiujiu") }}</div>
                    <div class="name">{{ userInfo.username }}</div>
                    <div class="bannerFoot">
                      <div class="left">{{ $t("home.取款人") }}：</div>
                      <div class="right">
                        <span></span> {{ $t("home.绑定时间") }}：{{
                          userInfo.createTime
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 2">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.转账中心") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="zhuanRow">
              <div class="zhuanItem flex_center">
                <img src="../assets/images/model/zhong.png" alt="" />
                <div class="title">{{ $t("home.中心钱包") }}</div>
                <div class="lan">0.00</div>
              </div>
              <div class="zhuanItem flex_center">
                <img src="../assets/images/model/suo.png" alt="" />
                <div class="title">{{ $t("home.锁定钱包") }}</div>
                <div class="lan">0.00</div>
              </div>
              <div class="zhuanItem flex_center">
                <div class="title">{{ $t("home.钱包金额") }}</div>
                <img src="../assets/images/model/lanshua.png" alt="" />
              </div>
              <div class="zhuanItem flex_center">
                <div class="lan">{{ $t("home.一键回收") }}</div>
              </div>
            </div>
            <div class="itemRow">
              <div class="rowItem" v-for="i in 22" :key="i">
                <div class="title">{{ $t("home.DG视讯") }}</div>
                <div class="lan">0.00</div>
                <div class="shu"></div>
              </div>
              <div class="rowItem">
                <div class="title">{{ $t("home.收起") }}</div>
                <img src="../assets/images/model/shang.png" alt="" />
              </div>
            </div>
            <div class="zidogn">
              {{ $t("home.自动转账") }}
              <img
                v-if="ziAuto"
                @click="closeTransfer"
                src="../assets/images/model/hua.png"
                alt=""
              />
              <img
                v-if="!ziAuto"
                @click="ziAuto = !ziAuto"
                src="../assets/images/model/off.png"
                alt=""
              />
            </div>

            <div class="zhuanFoot" v-if="!ziAuto">
              <div class="cpTimeBox">
                <div class="title">{{ $t("home.请选择") }}</div>
                <div class="right">
                  <img src="../assets/images/model/san.png" alt="" />
                </div>
              </div>
              <div class="cpTimeBox" style="width: 170px; margin-left: 65px">
                <div class="title">{{ $t("home.请选择") }}</div>
                <div class="right">
                  <img src="../assets/images/model/shagn.png" alt="" />
                </div>
              </div>
              <div class="shu"></div>
              <div class="moneyBox">
                <span>¥</span>
                <input
                  autocomplete="new-password"
                  type="number"
                  class="inputBox"
                />
              </div>
              <div class="allText">{{ $t("home.全部金额") }}</div>
            </div>
            <div class="zhuanBtn flex_center" v-if="!ziAuto">
              {{ $t("home.立即转账") }}
            </div>
            <div class="zhuanTips" v-if="ziAuto">
              {{ $t("home.ban") }}<span>{{ $t("home.联系客服") }}</span>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 3 && mineIndex == 0">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.转账中心") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czRightTitle" style="border-bottom: none">
              <div class="leftBox" style="align-items: center">
                <el-radio
                  v-model="radio4"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.今日") }}</el-radio
                >
                <el-radio v-model="radio4" label="2">{{
                  $t("home.昨天")
                }}</el-radio>
                <el-radio v-model="radio4" label="3">{{
                  $t("home.本月")
                }}</el-radio>
                <el-radio v-model="radio4" label="4">{{
                  $t("home.上月")
                }}</el-radio>
                <div class="timePickBox">
                  <el-date-picker
                    v-model="value2"
                    type="date"
                    :placeholder="$t('home.选择时间')"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="jisuan">
                {{ $t("home.计算公式") }}
                <img src="../assets/images/model/wen.png" alt="" />
              </div>
            </div>
            <div class="mineBox">
              <div
                class="mineItem"
                v-for="(item, index) in mineInfoList"
                :key="index"
              >
                <div class="title">{{ item.title }}</div>
                <div class="lan">{{ item.money }}</div>
                <div class="shu"></div>
              </div>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 3 && mineIndex == 1">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.我的资料") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="mineInfoBox">
              <div class="mineInfoItem">
                <div class="left">{{ $t("home.账号") }}：</div>
                <div class="right">{{ userInfo.username }}</div>
              </div>
              <div class="mineInfoItem">
                <div class="left">{{ $t("home.真实姓名") }}：</div>
                <div class="right"></div>
              </div>
              <div class="mineInfoItem">
                <div class="left">{{ $t("home.游戏账号") }}：</div>
                <div class="right">
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.请输入游戏地址')"
                    placeholder-class="place"
                  />
                </div>
              </div>
              <div class="mineInfoItem">
                <div class="left">{{ $t("home.手机号") }}：</div>
                <div class="right">
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.请输入手机号码')"
                    placeholder-class="place"
                  />
                </div>
              </div>
              <div class="mineInfoItem">
                <div class="left">{{ $t("home.微信号码") }}：</div>
                <div class="right">
                  <input
                    autocomplete="new-password"
                    type="text"
                    :placeholder="$t('home.请输入微信号')"
                    placeholder-class="place"
                  />
                </div>
              </div>
            </div>
            <div class="submitBtn flex_center">{{ $t("home.确认修改") }}</div>
          </div>
          <div
            class="czRight"
            style="padding: 0"
            v-if="menuIndex == 3 && mineIndex == 2"
          >
            <div class="czRightTitle" style="margin-left: 22px">
              <div class="left">{{ $t("home.投注记录") }}</div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czRightTitle">
              <div class="leftBox" style="align-items: center">
                <!-- <div class="dayItem">
                  <img src="../assets/images/model/ac.png" alt="" />{{
                    $t("home.本周")
                  }}
                </div>
                <div class="dayItem">
                  <img src="../assets/images/model/a.png" alt="" />{{
                    $t("home.上周")
                  }}
                </div>
                <div class="dayItem">
                  <img src="../assets/images/model/a.png" alt="" />{{
                    $t("home.本月")
                  }}
                </div>
                <div class="dayItem">
                  <img src="../assets/images/model/a.png" alt="" />{{
                    $t("home.上月")
                  }}
                </div> -->
                <el-radio
                  v-model="radio5"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.本周") }}</el-radio
                >
                <el-radio v-model="radio5" label="2">{{
                  $t("home.上周")
                }}</el-radio>
                <el-radio v-model="radio5" label="3">{{
                  $t("home.本月")
                }}</el-radio>
                <el-radio v-model="radio5" label="4">{{
                  $t("home.上月")
                }}</el-radio>

                <div class="cpTimeBox">
                  <!-- <div class="title">{{ $t("home.彩票游戏") }}</div>
                  <div class="right">
                    <img src="../assets/images/model/san.png" alt="" />
                  </div> -->
                  <el-select
                    v-model="khvalue5"
                    :placeholder="$t('home.请选择')"
                  >
                    <el-option
                      v-for="item in options5"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="tzMain">
              <el-table :data="tzList.lotteryList.items" style="width: 100%">
                <el-table-column
                  width="230"
                  prop="actionTime"
                  :label="$t('home.时间')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="count"
                  :label="$t('home.笔数')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="amount"
                  :label="$t('home.投注金额')"
                  align="center"
                >
                </el-table-column>
                <el-table-column :label="$t('home.输赢')" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 0">未开奖</span>
                    <span v-if="scope.row.status == 1">中奖</span>
                    <span v-if="scope.row.status == 2">未中奖</span>
                    <span v-if="scope.row.status == 3">系统撤单</span>
                    <span v-if="scope.row.status == 4">个人撤单</span>
                    <span v-if="scope.row.status == 5">和局</span>
                    {{ scope.row.bonus }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cash_status"
                  :label="$t('home.操作')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="tzFoot">
              <div class="left">
                {{ $t("home.总投注") }}：
                <span>{{ tzList.total }}</span> &nbsp;,
                {{ $t("home.总输赢") }}：<span>{{ tzList.win }}</span>
              </div>
              <div class="right">
                <!-- <span>{{ $t("home.gong") }} 6 {{ $t("home.tiao") }}</span>
                <div class="btnBox">
                  <img src="../assets/images/model/left.png" alt="" />
                  <div class="numBtn flex_center">1</div>
                  <img src="../assets/images/model/right.png" alt="" />
                </div> -->
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page.sync="page"
                  :page-size="pageSize"
                  layout="total, prev, pager, next"
                  :total="tzList.total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0"
            v-if="menuIndex == 3 && mineIndex == 3"
          >
            <div class="czRightTitle">
              <div class="leftBox" style="align-items: center">
                <el-radio
                  v-model="radio6"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.今日") }}</el-radio
                >
                <el-radio v-model="radio6" label="2">{{
                  $t("home.昨天")
                }}</el-radio>
                <el-radio v-model="radio6" label="3">{{
                  $t("home.最近七天")
                }}</el-radio>
                <div class="timePickBox">
                  <el-date-picker
                    v-model="value3"
                    type="date"
                    :placeholder="$t('home.选择时间')"
                  >
                  </el-date-picker>
                </div>
                <div class="search flex_center">{{ $t("home.搜索") }}</div>
              </div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="tkMain">
              <el-table :data="chongzhiList" style="width: 100%">
                <el-table-column
                  prop="created_at"
                  :label="$t('home.存款时间')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="code"
                  :label="$t('home.流水号')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="amount"
                  :label="$t('home.金额')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="remark"
                  :label="$t('home.备注')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="status_text"
                  :label="$t('home.状态')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 3 && mineIndex == 8">
            <div class="czRightTitle">
              <div class="anBox">
                <div class="title flex_center">{{ $t("home.账号安全") }}</div>
                <div
                  class="anItem flex_center"
                  :class="{ anActive: anIndex == 0 }"
                  @click="anIndex = 0"
                >
                  {{ $t("home.修改登录密码") }}
                </div>
                <div
                  class="anItem flex_center"
                  :class="{ anActive: anIndex == 1 }"
                  @click="anIndex = 1"
                >
                  {{ $t("home.修改资金密码") }}
                </div>
              </div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="xiuBox" v-if="anIndex == 0">
              <div class="mineInfoBox" style="height: 200px">
                <div class="mineInfoItem">
                  <div class="left">{{ $t("home.旧密码") }}：</div>
                  <div class="right">
                    <input
                      autocomplete="new-password"
                      type="text"
                      :placeholder="$t('home.请输入旧密码')"
                      placeholder-class="place"
                      v-model="old_password"
                    />
                  </div>
                </div>
                <div class="mineInfoItem">
                  <div class="left">{{ $t("home.新密码") }}：</div>
                  <div class="right">
                    <el-input
                      autocomplete="new-password"
                      :placeholder="$t('home.请输入新密码')"
                      v-model="zijinmima"
                      show-password
                    ></el-input>
                  </div>
                </div>
                <div class="mineInfoItem">
                  <div class="left">{{ $t("home.确认密码") }}：</div>
                  <div class="right">
                    <el-input
                      autocomplete="new-password"
                      :placeholder="$t('home.请输入确认密码')"
                      v-model="password_confirmation"
                      show-password
                    ></el-input>
                  </div>
                </div>
              </div>
              <div class="submitBtn flex_center" @click="editLoginnmima">
                {{ $t("home.确认提交") }}
              </div>
            </div>
            <div class="xiuBox" v-if="anIndex == 1">
              <div class="mineInfoBox" style="height: 200px">
                <div class="mineInfoItem">
                  <div class="left">{{ $t("home.原资金密码") }}：</div>
                  <div class="right">
                    <input
                      autocomplete="new-password"
                      type="text"
                      :placeholder="$t('home.请输入6位原资金密码')"
                      placeholder-class="place"
                      v-model="old_password"
                    />
                  </div>
                </div>
                <div class="mineInfoItem">
                  <div class="left">{{ $t("home.新资金密码") }}：</div>
                  <div class="right">
                    <el-input
                      autocomplete="new-password"
                      :placeholder="$t('home.请输入6位新资金密码')"
                      v-model="zijinmima"
                      show-password
                    ></el-input>
                  </div>
                </div>
                <div class="mineInfoItem">
                  <div class="left">{{ $t("home.确认密码") }}：</div>
                  <div class="right">
                    <el-input
                      autocomplete="new-password"
                      :placeholder="$t('home.请输入6位确认密码')"
                      v-model="password_confirmation"
                      show-password
                    ></el-input>
                  </div>
                </div>
              </div>
              <div class="submitBtn flex_center" @click="editZijinmima">
                {{ $t("home.确认提交") }}
              </div>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0"
            v-if="menuIndex == 5 && daiIndex == 0"
          >
            <div class="czRightTitle" style="margin-left: 22px">
              <div class="left">{{ $t("home.我的收益") }}</div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div
              class="czRightTitle"
              style="
                height: 71px;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding-right: 47px;
              "
            >
              <div class="todayBox">
                <img src="../assets/images/model/today.png" alt="" />
                <div class="todayInfo">
                  <div class="today_title">{{ $t("home.今日收益") }}</div>
                  <div class="today_name">0.00</div>
                </div>
              </div>
              <div class="leftBox">
                <el-radio
                  v-model="radio8"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.本周") }}</el-radio
                >
                <el-radio v-model="radio8" label="2">{{
                  $t("home.上周")
                }}</el-radio>
                <el-radio v-model="radio8" label="3">{{
                  $t("home.本月")
                }}</el-radio>
                <el-radio v-model="radio8" label="4">{{
                  $t("home.上月")
                }}</el-radio>
              </div>
            </div>
            <div class="tzMain">
              <div class="tzHead">
                <div class="firstItem flex_center">{{ $t("home.时间") }}</div>
                <div class="item flex_center">{{ $t("home.总投注额") }}</div>
                <div class="item flex_center">{{ $t("home.直属投注额") }}</div>
                <div class="item flex_center">{{ $t("home.总分润额") }}</div>
                <div class="item flex_center">{{ $t("home.直属分额度") }}</div>
                <div class="item flex_center">{{ $t("home.操作") }}</div>
              </div>
              <div class="tzItem" v-for="i in 6" :key="i">
                <div class="firstItem flex_center">
                  2024-08-31 <span>星期六</span>
                </div>
                <div class="item flex_center">0</div>
                <div class="item flex_center">0.000000</div>
                <div class="item flex_center">0.000000</div>
                <div class="item flex_center">0.000000</div>
                <div class="item flex_center">
                  <span>{{ $t("home.详情") }}</span>
                </div>
              </div>
            </div>
            <div class="tzFoot">
              <div class="left">
                {{ $t("home.总分润") }}： <span>0.00</span>
              </div>
              <div class="right">
                <!--                <span>共 6 条</span>-->
                <div class="btnBox">
                  <img src="../assets/images/model/left.png" alt="" />
                  <div class="numBtn flex_center">1</div>
                  <img src="../assets/images/model/right.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 5 && daiIndex == 1">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.转账中心") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czRightTitle" style="border-bottom: none">
              <div class="leftBox" style="align-items: center">
                <el-radio
                  v-model="radio7"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.今日") }}</el-radio
                >
                <el-radio v-model="radio7" label="2">{{
                  $t("home.昨天")
                }}</el-radio>
                <el-radio v-model="radio7" label="3">{{
                  $t("home.本月")
                }}</el-radio>
                <el-radio v-model="radio7" label="4">{{
                  $t("home.上月")
                }}</el-radio>
                <div class="timePickBox">
                  <el-date-picker
                    v-model="value4"
                    type="date"
                    :placeholder="$t('home.选择时间')"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="jisuan">
                {{ $t("home.计算公式") }}
                <img src="../assets/images/model/wen.png" alt="" />
              </div>
            </div>
            <div class="daiBox">
              <div
                class="mineItem"
                :class="{ noneBorder: index > 7 }"
                v-for="(item, index) in daiInfoList"
                :key="index"
              >
                <div class="title">{{ item.title }}</div>
                <div class="lan">{{ item.money }}</div>
                <div class="shu"></div>
              </div>
            </div>
            <div class="daiFoot">
              <div class="footItem">
                <div class="title">{{ $t("home.推广链接") }}：</div>
                <div class="urlBox">http://www.xxxxxx.co</div>
                <div class="copyBtn flex_center">{{ $t("home.复制") }}</div>
              </div>
              <div class="footItem">
                <div class="title">{{ $t("home.邀请码") }}：</div>
                <div class="urlBox">http://www.xxxxxx.co</div>
                <div class="copyBtn flex_center">{{ $t("home.复制") }}</div>
              </div>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 5 && daiIndex == 2">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.下级报表") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czRightTitle" style="border-bottom: none">
              <div class="leftBox" style="align-items: center">
                <el-radio
                  v-model="radio7"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.今日") }}</el-radio
                >
                <el-radio v-model="radio7" label="2">{{
                  $t("home.昨天")
                }}</el-radio>
                <el-radio v-model="radio7" label="3">{{
                  $t("home.本月")
                }}</el-radio>
                <el-radio v-model="radio7" label="4">{{
                  $t("home.上月")
                }}</el-radio>
                <div class="timePickBox">
                  <el-date-picker
                    v-model="value4"
                    type="date"
                    :placeholder="$t('home.选择时间')"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column
                  prop="date"
                  :label="$t('tab.用户名')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('tab.类型')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.投注金额')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.中奖金额')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.团队返点')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.优惠礼金')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.盈利')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 5 && daiIndex == 3">
            <div class="czRightTitle">
              <div class="anBox" style="margin-left: 22px">
                <div class="title flex_center">{{ $t("home.账号安全") }}</div>
                <div
                  class="anItem flex_center"
                  :class="{ anActive: kaiIndex == 0 }"
                  @click="kaiIndex = 0"
                >
                  {{ $t("home.精准开户") }}
                </div>
                <div
                  class="anItem flex_center"
                  :class="{ anActive: kaiIndex == 1 }"
                  @click="kaiIndex = 1"
                >
                  {{ $t("home.创建邀请链接") }}
                </div>
                <div
                  class="anItem flex_center"
                  :class="{ anActive: kaiIndex == 2 }"
                  @click="kaiIndex = 2"
                >
                  {{ $t("home.管理邀请链接") }}
                </div>
              </div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="kaiBox" v-if="kaiIndex == 0">
              <div class="kaiItem">
                <div class="leftBox">
                  <div class="leftText">{{ $t("home.开户类型") }}</div>
                  <div class="inputBox">
                    <div class="itemBtn itemBtnActive">
                      {{ $t("home.代理") }}
                    </div>
                    <div class="itemBtn">{{ $t("home.会员") }}</div>
                  </div>
                </div>
                <div class="rightMainBox" @click="showPlays = true">
                  {{ $t("home.查看CP返点赔率表") }}
                </div>
              </div>
              <div class="kaiItem">
                <div class="leftBox">
                  <div class="leftText">{{ $t("home.开户账号") }}</div>
                  <div class="inputBox">
                    <input
                      autocomplete="new-password"
                      type="text"
                      :placeholder="$t('home.khzh')"
                      placeholder-class="place"
                    />
                  </div>
                </div>
              </div>
              <div class="kaiItem">
                <div class="leftBox">
                  <div class="leftText">{{ $t("home.默认密码") }}</div>
                  <div class="inputBox">
                    <input
                      autocomplete="new-password"
                      type="text"
                      v-model="password"
                      placeholder=""
                      placeholder-class="place"
                    />
                  </div>
                </div>
              </div>
              <div class="kaiItem">
                <div class="leftBox">
                  <div class="leftText">{{ $t("home.分润设置") }}</div>
                </div>
              </div>
              <div class="runMain">
                <div class="runBox">
                  <div class="runLeft">
                    <div class="runLeftItem">
                      <div class="runItem">
                        <div class="runName nameActive flex_center">快三：</div>
                        <div class="runDate dateActive flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                    <div class="runLeftItem" v-for="i in 6" :key="i">
                      <div class="runItem">
                        <div class="runName flex_center">六合彩：</div>
                        <div class="runDate flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="runRight">
                    <div class="setItem">
                      <div class="setName">{{ $t("home.统一设置") }}：</div>
                      <div class="setBox">
                        <div class="tong">
                          <img
                            src="../assets/images/model/jian.png"
                            class="jian"
                            alt=""
                          />
                          <img
                            src="../assets/images/model/jia.png"
                            class="jian jia"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="setItem" v-for="i in 6" :key="i">
                      <div class="setName">快开快3：</div>
                      <div class="setBox">
                        <div class="set_box">
                          <div class="set_rate">1.5%</div>
                          <div class="set_icon">
                            <img src="../assets/images/model/san.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="runBox">
                  <div class="runLeft">
                    <div class="runLeftItem">
                      <div class="runItem">
                        <div class="runName nameActive flex_center">快三：</div>
                        <div class="runDate dateActive flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                    <div class="runLeftItem" v-for="i in 6" :key="i">
                      <div class="runItem">
                        <div class="runName flex_center">六合彩：</div>
                        <div class="runDate flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="runRight">
                    <div class="setItem">
                      <div class="setName">{{ $t("home.统一设置") }}：</div>
                      <div class="setBox">
                        <div class="tong">
                          <img
                            src="../assets/images/model/jian.png"
                            class="jian"
                            alt=""
                          />
                          <img
                            src="../assets/images/model/jia.png"
                            class="jian jia"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="setItem" v-for="i in 6" :key="i">
                      <div class="setName">快开快3：</div>
                      <div class="setBox">
                        <div class="set_box">
                          <div class="set_rate">1.5%</div>
                          <div class="set_icon">
                            <img src="../assets/images/model/san.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kaiBox" v-if="kaiIndex == 1">
              <div class="kaiItem">
                <div class="leftBox">
                  <div class="leftText">{{ $t("home.开户类型") }}</div>
                  <div class="inputBox">
                    <div class="itemBtn itemBtnActive">
                      {{ $t("home.代理") }}
                    </div>
                    <div class="itemBtn">{{ $t("home.会员") }}</div>
                  </div>
                </div>
                <div class="rightMainBox" @click="showPlays = true">
                  {{ $t("home.查看CP返点赔率表") }}
                </div>
              </div>
              <div class="kaiItem">
                <div class="leftBox">
                  <div class="leftText">{{ $t("home.分润设置") }}</div>
                </div>
              </div>
              <div class="runMain">
                <div class="runBox">
                  <div class="runLeft">
                    <div class="runLeftItem">
                      <div class="runItem">
                        <div class="runName nameActive flex_center">快三：</div>
                        <div class="runDate dateActive flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                    <div class="runLeftItem" v-for="i in 6" :key="i">
                      <div class="runItem">
                        <div class="runName flex_center">六合彩：</div>
                        <div class="runDate flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="runRight">
                    <div class="setItem">
                      <div class="setName">{{ $t("home.统一设置") }}：</div>
                      <div class="setBox">
                        <div class="tong">
                          <img
                            src="../assets/images/model/jian.png"
                            class="jian"
                            alt=""
                          />
                          <img
                            src="../assets/images/model/jia.png"
                            class="jian jia"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="setItem" v-for="i in 6" :key="i">
                      <div class="setName">快开快3：</div>
                      <div class="setBox">
                        <div class="set_box">
                          <div class="set_rate">1.5%</div>
                          <div class="set_icon">
                            <img src="../assets/images/model/san.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="runBox">
                  <div class="runLeft">
                    <div class="runLeftItem">
                      <div class="runItem">
                        <div class="runName nameActive flex_center">快三：</div>
                        <div class="runDate dateActive flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                    <div class="runLeftItem" v-for="i in 6" :key="i">
                      <div class="runItem">
                        <div class="runName flex_center">六合彩：</div>
                        <div class="runDate flex_center">1.5%</div>
                      </div>
                      <div class="runIcon">
                        <img src="../assets/images/model/runIcon.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="runRight">
                    <div class="setItem">
                      <div class="setName">{{ $t("home.统一设置") }}：</div>
                      <div class="setBox">
                        <div class="tong">
                          <img
                            src="../assets/images/model/jian.png"
                            class="jian"
                            alt=""
                          />
                          <img
                            src="../assets/images/model/jia.png"
                            class="jian jia"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="setItem" v-for="i in 6" :key="i">
                      <div class="setName">快开快3：</div>
                      <div class="setBox">
                        <div class="set_box">
                          <div class="set_rate">1.5%</div>
                          <div class="set_icon">
                            <img src="../assets/images/model/san.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kaiBox" v-if="kaiIndex == 2">
              <div class="kaiItem">
                <div class="leftBox">
                  <div class="leftText">{{ $t("home.开户类型") }}</div>
                  <div class="inputBox">
                    <div class="itemBtn itemBtnActive">
                      {{ $t("home.全部") }}
                    </div>
                    <div class="itemBtn" style="margin-left: 34px">
                      {{ $t("home.代理") }}
                    </div>
                    <div class="itemBtn">{{ $t("home.会员") }}</div>
                  </div>
                </div>
              </div>
              <div class="tzHead">
                <div class="firstItem flex_center">
                  {{ $t("home.邀请链接") }}
                </div>
                <div class="firstItem flex_center">
                  {{ $t("home.生成时间") }}
                </div>
                <div class="item flex_center">{{ $t("home.状态") }}</div>
                <div class="item flex_center">{{ $t("home.注册人数") }}</div>
                <div class="item flex_center">{{ $t("home.操作") }}</div>
              </div>
              <div class="tzItem" v-for="i in 6" :key="i">
                <div class="firstItem flex_center">
                  2024-08-31 <span>星期六</span>
                </div>
                <div class="firstItem flex_center">0</div>
                <div class="item flex_center">0.000000</div>
                <div class="item flex_center">0.000000</div>
                <div class="item flex_center">
                  <span>{{ $t("home.详情") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 5 && daiIndex == 4">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.下级列表") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czRightTitle" style="border-bottom: none">
              <div class="leftBox" style="align-items: center">
                <el-input
                  autocomplete="new-password"
                  v-model="xiajiinput"
                  :placeholder="$t('tab.请输入')"
                ></el-input>
                <el-button type="primary">{{ $t("home.搜索") }}</el-button>
              </div>
            </div>
            <div>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column
                  prop="date"
                  :label="$t('tab.帐号')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('tab.姓名')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.用户类型')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.余额')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.注册时间')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.最后登录')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.状态')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 5 && daiIndex == 5">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.下级财务") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czRightTitle" style="border-bottom: none">
              <div class="leftBox" style="align-items: center">
                <el-input
                  autocomplete="new-password"
                  style="width: 200px"
                  size="small"
                  v-model="caiwuinput"
                  :placeholder="$t('tab.请输入帐号')"
                ></el-input>
                <div>{{ $t("tab.状态") }}</div>
                <div>
                  <el-select
                    v-model="caiwuvalue"
                    placeholder="请选择"
                    style="width: 150px"
                  >
                    <el-option
                      v-for="item in caiwuoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <el-button type="primary">{{ $t("home.搜索") }}</el-button>
                <el-radio
                  v-model="radio3"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.今日") }}</el-radio
                >
                <el-radio v-model="radio3" label="2">{{
                  $t("home.昨天")
                }}</el-radio>
                <el-radio v-model="radio3" label="3">{{
                  $t("home.最近七天")
                }}</el-radio>
                <div class="timePickBox" style="width: 180px">
                  <el-date-picker
                    v-model="value5"
                    type="date"
                    :placeholder="$t('home.选择时间')"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div>
              <el-table :data="tableData2" style="width: 100%">
                <el-table-column
                  prop="date"
                  :label="$t('tab.帐号')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('tab.存款方式')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.存款时间')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.存款金额')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.状态')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="czRight" v-if="menuIndex == 5 && daiIndex == 6">
            <div class="czRightTitle">
              <div class="left">{{ $t("home.下级人数") }}</div>
              <div class="closeBtn" @click="centerModel = false">
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="czRightTitle" style="border-bottom: none">
              <div class="leftBox" style="align-items: center">
                <el-radio
                  v-model="radio9"
                  label="1"
                  style="margin-left: 10px"
                  >{{ $t("home.本周") }}</el-radio
                >
                <el-radio v-model="radio9" label="2">{{
                  $t("home.上周")
                }}</el-radio>
                <el-radio v-model="radio9" label="3">{{
                  $t("home.本月")
                }}</el-radio>
                <el-radio v-model="radio9" label="4">{{
                  $t("home.上月")
                }}</el-radio>
              </div>
            </div>
            <div>
              <el-table :data="tableData3" style="width: 100%">
                <el-table-column
                  prop="date"
                  :label="$t('tab.时间')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('tab.总人数')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.新增用户')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.存款人数')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('tab.存款金额')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            class="czRight"
            style="padding: 0 0 0 29px"
            v-if="menuIndex == 6 && systemIndex == 0"
          >
            <div class="czRightTitle">
              <div class="left">{{ $t("home.系统信息") }}</div>
              <div
                class="closeBtn"
                style="right: 0"
                @click="centerModel = false"
              >
                <img src="../assets/images/model/closeBtn.png" alt="" />
              </div>
            </div>
            <div class="systemBox">
              <div class="systemLeft">
                <div
                  class="systemItem"
                  v-for="(m, m1) in msgList"
                  :key="m1"
                  @click="selectMsg(m)"
                >
                  <div class="day">
                    <div class="dayNum">{{ m.day }}</div>
                    <div class="dayName">{{ m.week }}</div>
                  </div>
                  <div class="rBorder">
                    <div class="yuanBox">
                      <div class="yuan"></div>
                      <div class="systemText">{{ m.type }}</div>
                    </div>
                  </div>
                  <div class="systemTextBox">
                    <div class="systemTextTitle">{{ m.title }}</div>
                    <div class="systemTextTips" v-html="m.content"></div>
                  </div>
                </div>
              </div>
              <div class="systemRight">
                <div class="sysTitle">{{ selectmsg.title }}</div>
                <div class="sysTime">{{ selectmsg.time }}</div>
                <div class="sysCont" v-html="selectmsg.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showTips" round>
      <div class="showTips">
        <div class="title">{{ $t("home.温馨提示") }}</div>
        <div class="tips">{{ $t("home.guanbi") }}</div>
        <div class="showTipsFoot">
          <div class="item flex_center" @click="cancel">
            {{ $t("home.取消") }}
          </div>
          <div class="item flex_center" @click="confirm">
            {{ $t("home.确认") }}
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showRechange" round>
      <div class="modelBox">
        <div class="modelHead" style="background: #e5e5e5; color: #696969">
          {{ $t("home.xiaoxi") }}
          <img
            src="../assets/images/model/closeFang.png"
            @click="showRechange = false"
            alt=""
          />
        </div>
        <div class="modelCont" style="flex-direction: column">
          <span style="display: flex; align-items: center"
            ><img src="../assets/images/model/gan.png" alt="" />{{
              $t("home.是否继续该笔充值")
            }}</span
          >
          <div class="payBtn flex_center" @click="showRechange = false">
            {{ $t("home.支付") }}
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showPlays" round>
      <div class="showPlaysBox">
        <div class="head">
          {{ $t("home.彩票返点赔率表") }}
          <div class="closeBtn" style="right: 0" @click="showPlays = false">
            <img src="../assets/images/model/closeBtn.png" alt="" />
          </div>
        </div>
        <div class="biaoContent">
          <div class="cpTimeBox">
            <div class="title">{{ $t("home.请选择") }}</div>
            <div class="right">
              <img src="../assets/images/model/san.png" alt="" />
            </div>
          </div>
          <div class="dian">{{ $t("home.抽取点数") }}</div>
          <div class="cpTimeBox">
            <div class="title">0.3</div>
          </div>
          <div class="lanBtn flex_center">{{ $t("home.计算赔率") }}</div>
        </div>
        <div class="thBox">
          <div class="thHead">
            <div class="thItem flex_center">{{ $t("home.游戏玩法") }}</div>
            <div class="thItem flex_center">{{ $t("home.下级点数") }}</div>
            <div class="thItem flex_center">{{ $t("home.下级赔率") }}</div>
          </div>
          <div class="thMain">
            <div class="thItem flex_center">0</div>
            <div class="thItem flex_center">0</div>
            <div class="thItem flex_center">0</div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="daiZhuan" round>
      <div class="zhuanBox">
        <div class="boxTitle flex_center">{{ $t("home.代理佣金转账") }}</div>
        <div class="boxMain">
          <div class="boxMainTitle">
            {{ $t("home.代理佣金") }}：0.00{{ $t("home.yuan") }}
          </div>
          <div class="boxMainAll">
            <div class="input">
              <div class="bi">{{ $t("home.danwei") }}</div>
              <input
                autocomplete="new-password"
                type="text"
                :placeholder="$t('home.转账金额')"
                placeholder-class="place"
              />
            </div>
            <div class="allText">{{ $t("home.全部") }}</div>
          </div>
        </div>

        <div class="boxFoot">
          <div class="footItem flex_center" @click="daiZhuan = false">
            {{ $t("home.取消") }}
          </div>
          <div class="shu"></div>
          <div class="footItem flex_center" @click="daiZhuan = false">
            {{ $t("home.确定") }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "TopHead",
  components: {},
  props: {
    //在一级页面还是2级页面显示
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      radio: "1", // 网银转账金额选择
      radio1: "1", // U币支付金额选择
      radio2: "1", // k豆钱包金额选择
      radio3: "1", // 提款记录选择
      radio4: "1", // 个人报表月份选择
      radio5: "1", // 个人投注记录月份选择
      radio6: "1", // 个人取款记录月份选择
      radio7: "1", // 代理报表选择
      radio8: "1", // 我的收益选择
      radio9: "1", // 下级人数选择
      radio10: "1", // 下级财务选择
      value1: "", // 提款记录时间选择
      value2: "", // 个人报表时间选择
      value5: "", // 下级财务时间选择
      value3: "", // 个人取款记录时间选择
      value4: "", // 代理报表时间选择
      khvalue: "", // 提款绑定银行卡开户行选择
      options: [], // 提款绑定银行卡开户行列表
      khvalue1: "", // 提款绑定银行卡开户网点选择
      options1: [], // 提款绑定银行卡开户网点列表
      khvalue2: "", // 提款绑定银行卡开户网点选择
      options2: [], // 提款绑定银行卡开户网点列表
      khvalue3: "", // 绑定USDT币种选择
      options3: [], // 绑定USDT币种列表
      khvalue4: "", // 绑定钱包第三方选择
      options4: [], // 绑定钱包第三方列表
      khvalue5: this.$t("home.彩票游戏"), // 绑定钱包第三方选择
      options5: [], // 绑定钱包第三方列表
      caiwuoptions: [], // 下级财务状态列表
      tableData: [], //下级报表列表数据
      tableData1: [], //下级列表数据
      tableData2: [], //下级财务数据
      tableData3: [], //下级人数数据
      xiajiinput: "", // 下级列表搜索
      caiwuinput: "", // 下级财务搜索
      caiwuvalue: "", // 下级财务状态选择
      bankInfos: {}, // 银行卡信息
      userInfo: "",
      password: "",
      username: "",
      showTips: false,
      daiZhuan: false,
      ziAuto: true,
      showPlays: false,
      showModel: false,
      centerModel: false, // 我的信息大的弹窗
      showRechange: false,
      menuIndex: 0,
      czIndex: 0,
      tkIndex: 0,
      daiIndex: 0,
      mineIndex: 0,
      anIndex: 0,
      kaiIndex: 0,
      systemIndex: 0,
      menuList: [
        {
          img: require("../assets/images/model/l0.png"),
          title: this.$t("home.充值"),
        },
        {
          img: require("../assets/images/model/l1.png"),
          title: this.$t("home.提款"),
        },
        {
          img: require("../assets/images/model/l2.png"),
          title: this.$t("home.转账"),
        },
        {
          img: require("../assets/images/model/l3.png"),
          title: this.$t("home.个人"),
        },
        {
          img: require("../assets/images/model/l4.png"),
          title: this.$t("home.优惠"),
        },
        {
          img: require("../assets/images/model/l5.png"),
          title: this.$t("home.代理"),
        },
        {
          img: require("../assets/images/model/l6.png"),
          title: this.$t("home.信息"),
        },
        {
          img: require("../assets/images/model/l7.png"),
          title: this.$t("home.客服"),
        },
      ],
      czList: [
        this.$t("home.网银转账"),
        this.$t("home.U币支付"),
        this.$t("home.K豆钱包"),
        this.$t("home.JDpay"),
        this.$t("home.C币钱包"),
        this.$t("home.jiujiu"),
        this.$t("home.钱能钱包"),
        this.$t("home.钱包支付"),
        this.$t("home.支付宝支付"),
        this.$t("home.VIP充值"),
      ],
      tkList: [
        this.$t("home.提款记录"),
        this.$t("home.人民币取款"),
        this.$t("home.钱包取款"),
        this.$t("home.USDT取款"),
        this.$t("home.U币取款"),
        this.$t("home.绑定银行卡"),
        this.$t("home.绑定USDT"),
        this.$t("home.绑定钱包"),
      ],
      mineList: [
        this.$t("home.个人报表"),
        this.$t("home.我的资料"),
        this.$t("home.投注记录"),
        this.$t("home.存款记录"),
        this.$t("home.取款记录"),
        this.$t("home.优惠记录"),
        this.$t("home.代理记录"),
        this.$t("home.其他记录"),
        this.$t("home.账号安全"),
      ],
      daiList: [
        this.$t("home.我的收益"),
        this.$t("home.代理报表"),
        this.$t("home.下级报表"),
        this.$t("home.下级开户"),
        this.$t("home.下级列表"),
        this.$t("home.下级财务"),
        this.$t("home.下级人数"),
      ],
      systemList: [
        this.$t("home.系统信息"),
        this.$t("home.投诉建议"),
        this.$t("home.已发信息"),
      ],
      mineInfoList: [
        { title: this.$t("home.会员余额"), money: 0 },
        { title: this.$t("home.存款金额"), money: 0 },
        { title: this.$t("home.取款金额"), money: 0 },
        { title: this.$t("home.会员输赢"), money: 0 },
        { title: this.$t("home.投资金额"), money: 0 },
        { title: this.$t("home.优惠金额"), money: 0 },
      ],
      daiInfoList: [
        { title: this.$t("home.团队净盈利"), money: 0 },
        { title: this.$t("home.团队返点"), money: 0 },
        { title: this.$t("home.团队余额"), money: 0 },
        { title: this.$t("home.新增用户"), money: 0 },
        { title: this.$t("home.下级总人数"), money: 0 },
        { title: this.$t("home.首存人数"), money: 0 },
        { title: this.$t("home.投注人数"), money: 0 },
        { title: this.$t("home.有效投注"), money: 0 },
        { title: this.$t("home.中奖金额"), money: 0 },
        { title: this.$t("home.存款金额"), money: 0 },
        { title: this.$t("home.取款金额"), money: 0 },
        { title: this.$t("home.活动礼金"), money: 0 },
      ],
      btnList: [
        { title: this.$t("home.充值"), itemIndex: 0 },
        { title: this.$t("home.提现"), itemIndex: 1 },
        { title: this.$t("home.个人中心"), itemIndex: 3 },
        { title: this.$t("home.投注记录"), itemIndex: 3, sonIndex: 2 },
        { title: this.$t("home.退出"), itemIndex: -1 },
      ],
      qianbaomoney: "", //钱包提现的余额
      old_password: "", //旧密码
      zijinmima: "", //钱包提现的资金密码
      password_confirmation: "", //钱包提现的确认资金密码
      banckNum: "", //绑定银行卡的卡号
      banckaddress: "", //绑定银行卡开户行
      banckInfo: [], //绑定银行卡的信息
      tixianList: [], //提现记录列表
      chongzhiList: [], //充值记录列表
      tzList: [], //投注记录列表
      msgList: [], //消息列表
      intervalId: null, //刷新金额的定时器
      canclick: true,
      selectmsg: {}, //选择的消息
      page: 1, //分页
      pageSize: 5, //分页条数
      noLogin: false,
    };
  },
  watch: {
    menuIndex() {
      this.page = 1;
      this.pageSize = 5;
    },
    czIndex() {
      this.page = 1;
      this.pageSize = 5;
    },
    tkIndex() {
      this.page = 1;
      this.pageSize = 5;
      if (this.tkIndex == 5) {
        this.getbank();
      }
    },
    mineIndex() {
      this.page = 1;
      this.pageSize = 5;
      if (this.mineIndex == 0) {
        this.getNew();
      }
    },
    daiIndex() {
      this.page = 1;
      this.pageSize = 5;
    },
    systemIndex() {
      this.page = 1;
      this.pageSize = 5;
    },
  },
  mounted() {
    if (
      localStorage.getItem("token") == "" ||
      localStorage.getItem("token") == null
    ) {
      this.outLogin();
      this.userInfo = "";
    } else {
      this.getNew();
      this.getInfo();
      this.intervalId = setInterval(() => {
        this.getNew();
      }, 2000);
    }
  },
  methods: {
    //消息选择展示
    selectMsg(item) {
      this.selectmsg = item;
    },
    //点击会员中心
    showModelInfo() {
      this.centerModel = true;
      this.menuIndex = 3;
      this.mineIndex = 0;
    },
    //关闭信息弹窗
    chongzhi() {
      this.czIndex = 0;
      this.tkIndex = 0;
      this.daiIndex = 0;
      this.mineIndex = 0;
      this.anIndex = 0;
      this.kaiIndex = 0;
      this.systemIndex = 0;
    },
    //一键刷新
    shua() {
      this.getNew();
      this.showModel = true;
    },
    //获取用户信息
    getNew() {
      this.$axios
        .get(`/auth/me`)
        .then(({ data }) => {
          this.userInfo = data;
          localStorage.setItem("userInfo", JSON.stringify(data));
          this.mineInfoList[0].money = data.coin || 0;
          this.mineInfoList[1].money = data.rechargeTotal || 0;
          this.mineInfoList[2].money = data.cashTotal || 0;
          this.mineInfoList[3].money = data.bonusRate || 0;
        })
        .catch((error) => {
          clearInterval(this.intervalId);
          this.outLogin();
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //二级页面顶部方法
    showModelfun(item) {
      if (item.itemIndex != -1) {
        if (item.itemIndex == 1) {
          this.gettixian();
        }
        if (item.itemIndex == 3 && item.sonIndex == 2) {
          this.touzhuList();
        }
        this.centerModel = true;
        this.menuIndex = item.itemIndex;
        if (item.sonIndex) {
          this.mineIndex = item.sonIndex;
        } else {
          this.mineIndex = 0;
        }
      } else {
        this.$router.push("/");
      }
    },
    //获取充值页面
    getInfo() {
      this.$axios
        .get(`/money/recharge`)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //转账
    cunkuan() {
      if (this.canclick == false) {
        return;
      } else {
        this.canclick = false;
        this.$axios
          .post(`/money/rechargeTransferDo/13`, {
            count: this.radio == 1 ? 300 : this.radio == 2 ? 1000 : 10000,
          })
          .then(({ data }) => {
            console.log(data);
            this.canclick = true;
            this.$message({
              message: this.$i18n.t("login.insuccess"),
              type: "success",
              customClass: "messageIndex",
            });
          })
          .catch((error) => {
            let msg = error.msg;
            this.$message.error(msg);
          })
          .finally(() => {});
      }
    },
    //提现
    tixian() {
      this.$axios
        .post(`/money/cashDo/${this.banckInfo[0].id}`, {
          count: this.qianbaomoney,
          password: this.zijinmima,
        })
        .then(({ data }) => {
          console.log(data);
          this.$message.success("提现成功");
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //退出登录
    outLogin() {
      localStorage.setItem("token", "");
      localStorage.setItem("userInfo", "");
      this.userInfo = "";
      this.$emit("loginOut");
    },
    //提款二级菜单选择
    tikuan2tab(val) {
      this.tkIndex = val;
      this.getbank1();
      if (val == 5) {
        this.getbank();
      }
      if (val == 0) {
        this.gettixian();
      }
    },
    //个人二级菜单选择
    gerentab(val) {
      this.mineIndex = val;
      if (val == 3) {
        this.getchongzhi();
      }
      if (val == 4) {
        this.gettixian();
      }
      if (val == 2) {
        this.touzhuList();
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.touzhuList();
    },
    //投注记录
    touzhuList() {
      this.$axios
        .get(`/record/lottery`, {
          params: {
            page: this.page,
            pagesize: this.pageSize,
          },
        })
        .then(({ data }) => {
          this.tzList = data;
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //弹窗一级菜单选择
    levelSelect(val) {
      this.menuIndex = val;
      this.chongzhi();
      if (val == 1) {
        this.gettixian();
      }
      if (val == 3) {
        this.getNew();
      }
      if (val == 6) {
        this.systemIndex = 0;
        this.getmsg();
      }
    },
    //获取消息列表
    getmsg() {
      this.$axios
        .get(`/index/main`)
        .then(({ data }) => {
          this.msgList = data;
          this.selectmsg = data[0];
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //消息二级点击
    msgClick(t) {
      this.systemIndex = t;
      if (t == 0) {
        this.getmsg();
      }
    },
    //获取充值记录
    getchongzhi() {
      this.$axios
        .get(`/money/rechargeShow`)
        .then(({ data }) => {
          this.chongzhiList = data;
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //获取提现记录
    gettixian() {
      this.$axios
        .get(`/money/cash`)
        .then(({ data }) => {
          this.bankInfos = data.bank[0];
          this.tixianList = data.log;
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //获取银行列表
    getbank() {
      this.loading = true;
      this.$axios
        .get(`/auth/bank/list`)
        .then(({ data }) => {
          this.options = data;
          this.loading = false;
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //获取绑定银行卡详情
    getbank1() {
      this.$axios
        .get(`/auth/bank/get`)
        .then(({ data }) => {
          if (data.bank.length > 0) {
            this.banckInfo = data.bank;
            this.khvalue = data.bank[0].bank;
            this.banckNum = data.bank[0].code;
            this.username = data.bank[0].name;
            this.banckaddress = data.bank[0].open;
          } else {
            this.tkIndex = 5;
          }
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //绑定银行卡
    bandBank() {
      this.$axios
        .post(`/auth/bank/add`, {
          bank: this.khvalue,
          code: this.banckNum,
          open: this.banckaddress,
          name: this.username,
          password: this.zijinmima,
          password_confirmation: this.password_confirmation,
        })
        .then(({ data }) => {
          console.log(data);
          this.$message.success("绑定成功");
          this.getbank1();
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //修改资金密码
    editZijinmima() {
      this.$axios
        .post(`/auth/coin/password`, {
          old_password: this.old_password,
          password: this.zijinmima,
          password_confirmation: this.password_confirmation,
        })
        .then(({ data }) => {
          console.log(data);
          this.$message.success("修改成功");
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    //修改登录密码
    editLoginnmima() {
      this.$axios
        .post(`/auth/password/reset`, {
          old_password: this.old_password,
          password: this.zijinmima,
          password_confirmation: this.password_confirmation,
        })
        .then(({ data }) => {
          console.log(data);
          this.outLogin();
        })
        .catch((error) => {
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
    closeTransfer() {
      this.showTips = true;
    },
    confirm() {
      this.ziAuto = !this.ziAuto;
      this.showTips = false;
    },
    cancel() {
      this.showTips = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .cunMoneyBox .el-radio__label {
  font-size: 20px;
}
::v-deep .cunMoneyBox .el-radio {
  margin-bottom: 10px;
}
::v-deep .cpTimeBox .el-input__inner {
  background: transparent;
}
::v-deep .timePickBox .el-input__inner {
  background: transparent;
}
::v-deep .item .el-input__inner {
  background: #f9f9f9;
}
.messageIndex {
  z-index: 999 !important;
}
.zhuanBox {
  width: 345px;
  height: 224px;
  background: #403d58;
  border-radius: 6px;
  .boxTitle {
    border-bottom: 1px solid #4b495d;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 19px;
    color: #ffffff;
    height: 50px;
    box-sizing: border-box;
  }
  .boxMain {
    height: 135px;
    overflow: hidden;
    .boxMainTitle {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      margin-top: 15px;
    }
    .boxMainAll {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 22px;
      .input {
        width: 239px;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #6f6c7c;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        position: relative;
        padding-left: 26px;
        display: flex;
        align-items: center;
        .bi {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          position: absolute;
          left: 10px;
        }
      }
      .place {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
      }
      .allText {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 17px;
        color: #ffffff;
        margin-left: 12px;
      }
    }
  }
  .boxFoot {
    background: #d93d31;
    height: 40px;
    display: flex;
    .shu {
      width: 1px;
      height: 100%;
      background: rgba(255, 255, 255, 0.24);
    }
    .footItem {
      flex: 1;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
.showPlaysBox {
  width: 1022px;
  height: 576px;
  background: #ffffff;
  border-radius: 16px;
  .head {
    height: 67px;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 26px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    .closeBtn {
      width: 59px;
      height: 40px;
      background: #f2f2f2;
      border-radius: 100px 0px 0px 100px;
      display: flex;
      align-items: center;
      padding-left: 2px;
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
  .biaoContent {
    height: 90px;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    .cpTimeBox {
      width: 242px;
      height: 36px;
      background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);
      border-radius: 10px;
      border: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 16px;
      margin-left: 18px;
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
      }
      .right {
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 10px;
        img {
          width: 11px;
          height: 7px;
        }
      }
    }
    .dian {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin: 0 11px 0 22px;
    }
    .lanBtn {
      width: 108px;
      height: 36px;
      background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
      border-radius: 6px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      margin-left: 22px;
    }
  }
  .thBox {
    background: #efefef;
    .thHead {
      height: 30px;
      background: #f8f8f9;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dcdcdc;
      .thItem {
        flex: 1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #949494;
      }
    }
    .thMain {
      height: 50px;
      display: flex;
      align-items: center;
      .thItem {
        flex: 1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #485060;
      }
    }
  }
}
.place {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #bbbbbb;
}
.noneBorder {
  border-bottom: none !important;
}
.showTips {
  width: 280px;
  height: 166px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 19px;
    color: #000000;
    text-align: center;
    margin-top: 20px;
  }
  .tips {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    text-align: center;
    margin-top: 15px;
  }
  .showTipsFoot {
    border-top: 1px solid #e9edf3;
    height: 40px;
    display: flex;
    margin-top: 43px;
    .item {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 17px;
      color: #000000;
      flex: 1;
      border-right: 1px solid #e9edf3;
    }
    .item:last-child {
      border-right: none;
      color: #203bc4;
    }
  }
}
.modelMain {
  display: flex;
  .leftMenu {
    width: 240px;
    height: 650px;
    background: #436de4;
    border-radius: 14px 0px 0px 14px;
    position: relative;
    display: flex;
    .menuBg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 255px;
      background: url("@/assets/images/model/mockup_bg.png") no-repeat;
      background-size: 100% 100%;
      z-index: 1;
    }
    .menuBox {
      width: 80px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 2;
      .menuItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 44px;
          height: 42px;
          margin-bottom: 8px;
        }
        .itemTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .active {
        background: #436de4;
      }
    }
    .menuMain {
      flex: 1;
      .czBox {
        padding-top: 24px;
        position: relative;
        z-index: 10;
        .cTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 25px;
          color: #ffffff;
          text-align: center;
        }
        .cYue {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 4px 0;
          text-align: center;
          img {
            width: 16px;
            height: 15px;
            margin-left: 4px;
          }
        }
        .cDai {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          img {
            width: 16px;
            height: 12px;
            margin-left: 4px;
          }
        }
        .czList {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .czItem {
            width: 100px;
            height: 36px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
            margin-bottom: 15px;
            position: relative;
            .zeng {
              width: 54px;
              height: 22px;
              background: linear-gradient(270deg, #436de4 0%, #203bc4 100%);
              border-radius: 5px 5px 5px 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 11px;
              color: #ffffff;
              position: absolute;
              bottom: -18px;
              left: 76px;
            }
          }
          .czActive {
            background: #203bc4;
            border-radius: 9px;
          }
        }
      }
    }
  }
  .rightBox {
    width: 1029px;
    height: 650px;
    background: #ffffff;
    border-radius: 0px 14px 14px 0px;
    position: relative;
    .czRight {
      padding: 0 29px;
      .uPayBox {
        padding-left: 76px;
        .payTitle {
          display: flex;
          align-items: center;
          padding-top: 11px;
          .uIcon {
            width: 22px;
            height: 23px;
            margin-right: 4px;
          }
          .shua {
            margin-left: 9px;
            width: 18px;
            height: 17px;
          }
          .payYue {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #696969;
            span {
              margin-left: 9px;
            }
          }
          .buyUBtn {
            width: 71px;
            height: 31px;
            background: #ff7e37;
            border-radius: 7px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            margin-left: 129px;
          }
        }
        .payItem {
          display: flex;
          .pay_left {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #696969;
          }
          .pay_right {
            .u_cunBtn {
              width: 140px;
              height: 42px;
              background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
              border-radius: 9px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 18px;
              color: #ffffff;
              margin-left: 15px;
              margin-top: 19px;
            }
            input {
              width: 256px;
              height: 36px;
              background: #f9f9f9;
              border-radius: 10px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #000;
              padding-left: 8px;
              display: flex;
              align-items: center;
            }
            .tips {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 15px;
              color: #696969;
              line-height: 25px;
              span {
                color: #fe0100;
              }
            }
            .cunMoneyBox {
              padding-left: 0;
              padding-top: 8px;
              display: flex;
              flex-direction: column;
              .cunItem {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                .yuan {
                  width: 14px;
                  height: 14px;
                  border: 1px solid #dddee1;
                  border-radius: 50%;
                  margin-right: 5px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 16px;
                  color: #696969;
                }
              }
            }
          }
        }
      }
      .czMainBox {
        display: flex;
        height: 594px;
        .czItemLeft {
          width: 436px;
        }
        .czItemRight {
          flex: 1;
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ff7e37;
            display: flex;
            align-items: center;
            height: 42px;
          }
          .cunBox {
            width: 533px;
            padding-bottom: 19px;
            border-radius: 10px;
            border: 1px solid #ff7e37;
            .cunHead {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 8px 0 20px;
              height: 50px;
              .headTitle {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 30px;
                color: #696969;
              }
              .headTips {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #fe0100;
              }
            }
            .cunBg {
              width: 379px;
              height: 190px;
              background: url("@/assets/images/model/cunBg.png") no-repeat;
              background-size: 100% 100%;
              overflow: hidden;
              margin: 5px auto 0;
              img {
                width: 14px;
                height: 14px;
                margin-left: 9px;
              }
              .cunTitle {
                display: flex;
                align-items: center;
                margin-left: 68px;
                margin-top: 22px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 17px;
                color: #ffffff;
              }
              .cunMoney {
                margin-top: 37px;
                margin-left: 106px;
                font-weight: 600;
                font-size: 24px;
              }
              .cunName {
                margin-top: 40px;
                margin-left: 20px;
              }
            }
            .upLoadBox {
              display: flex;
              margin-top: 18px;
              padding-left: 9px;
              .left {
                padding-top: 3px;
                .upLoadTitle {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 13px;
                  color: #696969;
                }
                .tips {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 11px;
                  color: #fe0100;
                }
              }
              .right {
                width: 76px;
                height: 76px;
                border-radius: 8px;
                border: 1px solid #979797;
                margin-left: 6px;
              }
            }
          }
          .cunBtnBox {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 15px;
            .cunBtnItem {
              width: 185px;
              height: 44px;
              border-radius: 5px;
              border: 1px solid #ff7e37;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #ff7e37;
            }
            .cunBtnItem:last-child {
              background: #ff7e37;
              color: #fff;
              margin-left: 12px;
            }
          }
        }
      }
      .czRightTitle {
        height: 55px;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .anBox {
          display: flex;
          align-items: center;
          .title {
            width: 104px;
            height: 40px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 19px;
            color: #696969;
            border-right: 1px solid #dbdbdb;
          }
          .anItem {
            width: 136px;
            height: 36px;
            border-radius: 6px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #696969;
            margin-left: 20px;
          }
          .anActive {
            background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
            color: #fff;
          }
        }
        .todayBox {
          display: flex;
          align-items: center;
          padding-left: 25px;
          img {
            width: 40px;
            height: 40px;
            margin-right: 5px;
          }
          .todayInfo {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .today_title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 15px;
              color: #333333;
            }
            .today_name {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 17px;
              color: #203bc4;
            }
          }
        }
        .leftBox {
          display: flex;
          .dayItem {
            display: flex;
            align-items: center;
            margin-left: 14px;
            img {
              width: 14px;
              height: 14px;
              margin-right: 6px;
            }
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #696969;
          }
          .timePickBox {
            width: 236px;
            height: 36px;
            background: #f9f9f9;
            border-radius: 10px;
            margin: 0 21px 0 13px;
            padding: 0 0 0 13px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #bcbec3;
            }
            .lan {
            }
            .xia {
              height: 100%;
              display: flex;
              align-items: center;
              padding-right: 9px;
              img {
                width: 12px;
                height: 8px;
              }
            }
          }
          .cpTimeBox {
            width: 144px;
            height: 36px;
            background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);
            border-radius: 10px;
            border: 1px solid #ebebeb;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 16px;
            margin-left: 18px;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #203bc4;
            }
            .right {
              height: 100%;
              display: flex;
              align-items: center;
              padding-right: 10px;
              img {
                width: 11px;
                height: 7px;
              }
            }
          }
          .search {
            width: 80px;
            height: 36px;
            background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
            border-radius: 6px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
          }
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 23px;
            height: 24px;
            margin-right: 3px;
          }
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 20px;
          color: #333333;
          .search {
            width: 89px;
            height: 34px;
            background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
            border-radius: 8px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #ffffff;
            margin-left: 50px;
          }
        }
        .closeBtn {
          position: absolute;
          right: -29px;
          bottom: 2px;
          width: 59px;
          height: 40px;
          background: #f2f2f2;
          border-radius: 100px 0px 0px 100px;
          display: flex;
          align-items: center;
          padding-left: 2px;
          img {
            width: 36px;
            height: 36px;
          }
        }
        .jisuan {
          display: flex;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #203bc4;
          img {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
        }
      }
      .tkMain {
        .tkMainTitle {
          display: flex;
          border-bottom: 1px solid #dcdcdc;
          height: 30px;
          background: #f8f8f9;
          .tkHeadItem {
            flex: 1;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #949494;
          }
        }
        .tkMainBox {
          height: 555px;
          img {
            width: 194px;
            height: 196px;
          }
        }
      }
      .tzMain {
        display: flex;
        flex-direction: column;
        .tzHead {
          display: flex;
          border-bottom: 1px solid #dcdcdc;
          height: 30px;
          background: #f8f8f9;
          .firstItem {
            width: 30%;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #949494;
          }
          .item {
            flex: 1;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #949494;
          }
        }
        .tzItem {
          display: flex;
          align-items: center;
          height: 48px;
          border-bottom: 1px solid #e9eaec;
          .firstItem {
            width: 30%;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #485060;
            span {
              margin-left: 21px;
            }
          }
          .item {
            flex: 1;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #485060;
            span {
              color: #203bc4;
            }
          }
        }
      }
      .zhuanRow {
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid #dbdbdb;
        .zhuanItem {
          flex: 1;
          height: 30px;
          border-right: 1px solid #dbdbdb;
          img {
            width: 16px;
            height: 15px;
          }
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            margin: 0 5px;
          }
          .lan {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: #203bc4;
          }
        }
        .zhuanItem:last-child {
          border-right: none;
        }
      }
      .itemRow {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #dbdbdb;
        .rowItem {
          width: calc(100% / 7);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 9px 0 8px;
          position: relative;
          border-bottom: 1px solid #dbdbdb;
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
          .lan {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: #203bc4;
          }
          .shu {
            position: absolute;
            top: 15px;
            right: 0;
            width: 1px;
            height: 21px;
            background: #dbdbdb;
          }
          img {
            width: 16px;
            height: 20px;
          }
        }
        .rowItem:nth-child(22) {
          border-bottom: none;
        }
        .rowItem:nth-child(7),
        .rowItem:nth-child(14),
        .rowItem:nth-child(21) {
          .shu {
            display: none;
          }
        }
      }
      .zidogn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #dbdbdb;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #696969;
        height: 66px;
        img {
          width: 24px;
          height: 12px;
          margin-left: 5px;
        }
      }
      .zhuanTips {
        text-align: center;
        padding-top: 23px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #696969;
        span {
          color: #203bc4;
        }
      }
      .zhuanFoot {
        display: flex;
        align-items: center;
        margin-top: 24px;
        .cpTimeBox {
          width: 242px;
          height: 36px;
          background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);
          border-radius: 10px;
          border: 1px solid #ebebeb;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 16px;
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
          }
          .right {
            height: 100%;
            display: flex;
            align-items: center;
            padding-right: 10px;
            img {
              width: 11px;
              height: 7px;
            }
          }
        }
        .shu {
          width: 1px;
          height: 31px;
          background: #dbdbdb;
          margin: 0 50px;
        }
        .moneyBox {
          display: flex;
          align-items: center;
          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            margin-right: 12px;
          }
          .inputBox {
            width: 200px;
            height: 36px;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #ebebeb;
            padding-left: 16px;
            display: flex;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
          }
        }
        .allText {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #696969;
          margin-left: 46px;
        }
      }
      .zhuanBtn {
        width: 200px;
        height: 42px;
        background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
        border-radius: 9px;
        margin: 20px auto 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
      }
      .mineBox {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #dbdbdb;
        .mineItem {
          width: 25%;
          height: 121px;
          border-bottom: 1px solid #dbdbdb;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
          .lan {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: #203bc4;
            margin-top: 6px;
          }
          .shu {
            height: 56px;
            border-right: 1px solid #dbdbdb;
            position: absolute;
            top: 34px;
            right: 0;
          }
        }
        .mineItem:nth-child(4) {
          .shu {
            display: none;
          }
        }
        .mineItem:nth-child(5),
        .mineItem:last-child {
          border-bottom: none;
        }
      }
      .daiBox {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #dbdbdb;
        .mineItem {
          width: 25%;
          height: 121px;
          border-bottom: 1px solid #dbdbdb;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
          .lan {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: #203bc4;
            margin-top: 6px;
          }
          .shu {
            height: 56px;
            border-right: 1px solid #dbdbdb;
            position: absolute;
            top: 34px;
            right: 0;
          }
        }
        .mineItem:nth-child(4),
        .mineItem:nth-child(8),
        .mineItem:nth-child(12) {
          .shu {
            display: none;
          }
        }
      }
      .daiFoot {
        padding-top: 21px;
        .footItem {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 12px;
          .title {
            width: 75px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #696969;
          }
          .urlBox {
            width: 260px;
            height: 36px;
            background: #f9f9f9;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #bbbbbb;
            padding-left: 15px;
            margin: 0 5px;
          }
          .copyBtn {
            width: 50px;
            height: 36px;
            background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
            border-radius: 6px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
          }
        }
      }
      .mineInfoBox {
        height: 316px;
        border-bottom: 1px solid #dbdbdb;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .mineInfoItem {
          display: flex;
          align-items: center;
          .left {
            width: 150px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #696969;
            text-align: right;
          }
          .right {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #696969;
            text-align: left;
            input {
              width: 242px;
              height: 36px;
              background: #f9f9f9;
              border-radius: 10px;
              padding-left: 16px;
              display: flex;
              align-items: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #000;
            }
            .place {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #bbbbbb;
            }
          }
        }
      }
      .submitBtn {
        margin-top: 24px;
        margin-left: 142px;
        width: 140px;
        height: 42px;
        background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
        border-radius: 9px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
      }
      .tzFoot {
        position: absolute;
        width: 100%;
        bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 18px;
        padding-right: 35px;
        box-sizing: border-box;
        .left {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 17px;
          color: #696969;
          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #ff0000;
            margin-left: 5px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11px;
            color: #696969;
            margin-right: 23px;
          }
          .btnBox {
            display: flex;
            align-items: center;
            .numBtn {
              width: 24px;
              height: 24px;
              background: #203bc4;
              border-radius: 4px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 11px;
              color: #ffffff;
              margin: 0 8px;
            }
            img {
              width: 6px;
              height: 8px;
            }
          }
        }
      }
      .walletBox {
        display: flex;
        .walletLeft {
          width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .walletBanner {
            background: url("@/assets/images/model/wallet.png") no-repeat;
            background-size: 100% 100%;
            width: 383px;
            height: 210px;
            margin-top: 14px;
            margin-bottom: 38px;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 15px;
              color: #696969;
              margin-top: 30px;
              margin-left: 80px;
              margin-bottom: 34px;
            }
            .name {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 24px;
              color: #696969;
              margin-left: 20px;
            }
            .bannerFoot {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 16px 0 20px;
              margin-top: 60px;
              .left {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #696969;
              }
              .right {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #696969;
                display: flex;
                align-items: center;
                span {
                  width: 8px;
                  height: 8px;
                  background: #203bc4;
                  border-radius: 50%;
                  margin-right: 15px;
                }
              }
            }
          }
          .item {
            display: flex;
            align-items: center;
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 15px;
              color: #696969;
            }
            input {
              width: 242px;
              height: 36px;
              background: #f9f9f9;
              border-radius: 10px;
              padding-left: 16px;
              display: flex;
              align-items: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #000;
            }
            .allText {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #203bc4;
              margin-left: 13px;
            }
            .cpTimeBox {
              width: 242px;
              height: 36px;
              background: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);
              border-radius: 10px;
              border: 1px solid #ebebeb;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-left: 16px;
              .title {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #666666;
              }
              .right {
                height: 100%;
                display: flex;
                align-items: center;
                padding-right: 10px;
                img {
                  width: 11px;
                  height: 7px;
                }
              }
            }
          }
          .itemTips {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #696969;
            margin-top: 21px;
            margin-bottom: 39px;
          }
          .submitBtn {
            width: 140px;
            height: 42px;
            background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
            border-radius: 9px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
          }
        }
        .walletRight {
          flex: 1;
          background: #efefef;
          border-bottom: 1px solid #dcdcdc;
          height: 590px;
          display: flex;
          flex-direction: column;
          .walletRightHead {
            height: 30px;
            background: #f8f8f9;
            display: flex;
            align-items: center;
            .w30 {
              width: 25%;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #949494;
            }
            .item {
              width: calc((100% - 50%) / 3);
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #949494;
            }
          }
          .walletRightMain {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            img {
              width: 194px;
              height: 196px;
            }
            .walletBanner {
              background: url("@/assets/images/model/wallet.png") no-repeat;
              background-size: 100% 100%;
              width: 383px;
              height: 210px;
              margin-top: 30px;
              margin-bottom: 30px;
              .title {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 15px;
                color: #696969;
                margin-top: 30px;
                margin-left: 80px;
                margin-bottom: 34px;
              }
              .name {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 24px;
                color: #696969;
                margin-left: 20px;
              }
              .bannerFoot {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px 0 20px;
                margin-top: 60px;
                .left {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #696969;
                }
                .right {
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 14px;
                  color: #696969;
                  display: flex;
                  align-items: center;
                  span {
                    width: 8px;
                    height: 8px;
                    background: #203bc4;
                    border-radius: 50%;
                    margin-right: 15px;
                  }
                }
              }
            }
          }
        }
      }
      .systemBox {
        display: flex;
        height: 590px;
        .systemLeft {
          width: 535px;
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          .systemItem {
            display: flex;
            align-items: center;
            .day {
              .dayNum {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #696969;
              }
              .dayName {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 15px;
                color: #696969;
                margin-top: 3px;
              }
            }
            .rBorder {
              width: 1px;
              height: 90px;
              background: #dbdbdb;
              position: relative;
              margin-left: 30px;
              .yuanBox {
                position: absolute;
                top: 50%;
                left: -5px;
                transform: translate(0, -50%);
                display: flex;
                align-items: center;
                .yuan {
                  width: 11px;
                  height: 11px;
                  background: #203bc4;
                  border-radius: 50%;
                }
                .systemText {
                  background: url("@/assets/images/model/systemBg.png")
                    no-repeat;
                  background-size: 100% 100%;
                  width: 50px;
                  height: 25px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 15px;
                  color: #ffffff;
                  padding-left: 10px;
                  margin-left: 7px;
                }
              }
            }
            .systemTextBox {
              width: 336px;
              height: 86px;
              background: #f9f9f9;
              border-radius: 8px;
              padding: 5px 20px 5px;
              margin-left: 82px;
              box-sizing: border-box;
              overflow: hidden;
              .systemTextTitle {
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 17px;
                color: #333333;
              }
              .systemTextTips {
                height: 38px;
                overflow: hidden;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 11px;
                color: #696969;
                line-height: 18px;
                margin-top: 10px;
                box-sizing: border-box;
              }
            }
          }
        }
        .systemRight {
          flex: 1;
          background: #f2f2f2;
          .sysTitle {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #333333;
            text-align: center;
            margin-top: 24px;
          }
          .sysTime {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11px;
            color: #696969;
            margin-top: 3px;
            text-align: center;
          }
          .sysCont {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11px;
            color: #696969;
            line-height: 16px;
            padding: 0 20px;
            margin-top: 40px;
            overflow: scroll;
          }
        }
      }
      .kaiBox {
        padding-top: 11px;
        .kaiItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 18px;
          .leftBox {
            display: flex;
            align-items: center;
            .leftText {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 17px;
              color: #333333;
              margin-right: 19px;
            }
            .inputBox {
              display: flex;
              align-items: center;

              .itemBtn {
                width: 64px;
                height: 32px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #6b6b6b;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                border: 1px solid #999999;
              }
              .itemBtnActive {
                background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
                border-radius: 6px;
                border: 1px solid transparent;
                color: #ffffff;
              }
              .itemBtn:last-child {
                margin-left: 34px;
              }
              input {
                width: 240px;
                height: 36px;
                background: #f9f9f9;
                border-radius: 10px;
                padding-left: 16px;
                display: flex;
                align-items: center;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #666666;
              }
            }
          }
          .rightMainBox {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 17px;
            color: #203bc4;
          }
        }
        .runMain {
          display: flex;
          .runBox {
            display: flex;
            flex: 1;
            .runLeft {
              .runLeftItem {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                .runItem {
                  display: flex;
                  .runName {
                    width: 100px;
                    height: 30px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 15px;
                    color: #696969;
                    border-radius: 6px 0px 0px 6px;
                  }
                  .nameActive {
                    color: #ffffff;
                    background: linear-gradient(
                      180deg,
                      #436de4 0%,
                      #203bc4 100%
                    );
                  }
                  .runDate {
                    width: 90px;
                    height: 30px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 15px;
                    color: #696969;
                  }
                  .dateActive {
                    background: #f4f4f4;
                  }
                }
                .runIcon {
                  margin-left: 12px;
                  img {
                    width: 8px;
                    height: 14px;
                  }
                }
              }
            }
            .runRight {
              width: 230px;
              height: 275px;
              border: 1px solid #f5f5f5;
              margin-left: 30px;
              padding: 3px 12px 0 0;
              .setItem {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .setName {
                  flex: 1;
                  display: flex;
                  justify-content: flex-end;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 15px;
                  color: #696969;
                }
                .setBox {
                  width: 98px;
                  .tong {
                    display: flex;
                    align-items: center;
                    .jian {
                      width: 22px;
                      height: 22px;
                    }
                    .jia {
                      margin-left: 34px;
                    }
                  }
                  .set_box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 24px;
                    background: linear-gradient(
                      180deg,
                      #ffffff 0%,
                      #f1f1f1 100%
                    );
                    border-radius: 4px;
                    border: 1px solid #dddee1;
                    padding: 0 9px 0 19px;
                    box-sizing: border-box;
                    .set_rate {
                      font-family: PingFangSC, PingFang SC;
                      font-weight: 400;
                      font-size: 15px;
                      color: #666666;
                    }
                    .set_icon {
                      display: flex;
                      align-items: center;
                      width: 10px;
                      height: 6px;
                    }
                  }
                }
              }
            }
          }
        }
        .tzHead {
          display: flex;
          border-bottom: 1px solid #dcdcdc;
          height: 30px;
          background: #f8f8f9;
          margin: 0 -29px;
          .firstItem {
            width: 25%;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #949494;
          }
          .item {
            flex: 1;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #949494;
          }
        }
        .tzItem {
          display: flex;
          align-items: center;
          height: 48px;
          border-bottom: 1px solid #e9eaec;
          margin: 0 -29px;
          .firstItem {
            width: 25%;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #485060;
            span {
              margin-left: 21px;
            }
          }
          .item {
            flex: 1;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #485060;
            span {
              color: #203bc4;
            }
          }
        }
      }
    }
    .cunNameBox {
      display: flex;
      align-items: center;
      margin-top: 12px;
      .cunTitle {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #696969;
      }
      input {
        width: 256px;
        height: 36px;
        background: #f9f9f9;
        border-radius: 10px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        color: #000;
      }
      .place {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #bbbbbb;
      }
    }
    .bao {
      padding-left: 88px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #696969;
      margin-top: 4px;
    }
    .cunMoneyBox {
      padding-left: 88px;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      .cunItem {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .yuan {
          width: 14px;
          height: 14px;
          border: 1px solid #dddee1;
          border-radius: 50%;
          margin-right: 5px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #696969;
        }
      }
    }
    .cunBtn {
      margin-left: 124px;
      margin-top: 13px;
      width: 140px;
      height: 42px;
      background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
      border-radius: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
::v-deep .van-popup--center.van-popup--round {
  border-radius: 14px;
}
.modelBox {
  width: 500px;
  height: 203px;
  background: #ffffff;
  border-radius: 6px;
  .modelHead {
    height: 40px;
    background: linear-gradient(270deg, #436de4 0%, #203bc4 100%);
    border-radius: 6px 6px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    padding: 0 15px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .modelCont {
    height: 163px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 35px;
      height: 35px;
      margin-right: 13px;
    }
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    .payBtn {
      width: 160px;
      height: 35px;
      background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
      border-radius: 9px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      margin-top: 37px;
    }
  }
}
.headBox {
  height: 61px;
  background: #e3eaf0;
  display: flex;
  align-items: center;
  .w12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headLeft {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
      span {
        font-family: ArialMT;
        font-size: 15px;
        color: #6f83b0;
      }
      .marginSpan {
        margin: 0 18px;
        font-size: 16px;
      }
    }
    .headRight {
      display: flex;
      align-items: center;
      img {
        width: 31px;
        height: 30px;
      }
      .username {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #6f83b0;
        margin-left: 10px;
      }
      .yue {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #6f83b0;
        margin-left: 16px;
      }
      .shua {
        width: 70px;
        height: 30px;
        background: linear-gradient(90deg, #436de4 0%, #203bc4 100%);
        border-radius: 4px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #ffffff;
        margin-left: 17px;
        margin-right: 5px;
      }
      .member {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #6f83b0;
      }
      .member_margin {
        margin-left: 20px;
        margin-right: 10px;
      }
      .logout {
        width: 60px;
        height: 30px;
        background: #697dac;
        border-radius: 4px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #ffffff;
      }
    }
  }
  .w1200 {
    width: 1346px;
    min-width: 1346px;
    margin: 0 auto;
  }
  .w1200 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .lotteryLeft {
      display: flex;
      align-items: center;
      .logo {
        width: auto;
        height: 38px;
      }
      .zhang {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #6b6b6b;
        margin-left: 30px;
        .red {
          color: #fe0100;
        }
      }
      .btnList {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .btnItem {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #6b6b6b;
          display: flex;
          align-items: center;

          .shu {
            width: 1px;
            height: 15px;
            background: #6b6b6b;
            margin: 0 8px;
          }
        }
      }
    }
    .lotteryRight {
      display: flex;
      align-items: center;
      .rightItem {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #6b6b6b;
        display: flex;
        align-items: center;
        .img0 {
          width: 13px;
          height: 16px;
          margin-right: 6px;
        }
        .img1 {
          width: 17px;
          height: 16px;
          margin-right: 6px;
        }
        .img2 {
          width: 14px;
          height: 17px;
          margin-right: 6px;
        }
        .shu {
          width: 1px;
          height: 15px;
          background: #6b6b6b;
          margin: 0 9px;
        }
      }
    }
  }
}
</style>
