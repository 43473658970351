import axios from "axios";
import router from "@/router";
import Vue from "vue";
/*第一层if判断生产环境和开发环境*/
if (process.env.NODE_ENV === "production") {
  /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
  if (process.env.VUE_APP_FLAG === "prod") {
    //production 生产环境
    Vue.prototype.$baseUrl = "https://" + window.location.hostname + "/api/v1";
  } else {
    //test 测试环境
    Vue.prototype.$baseUrl = "https://" + window.location.hostname + "/api/v1";
  }
} else {
  //dev 开发环境
  Vue.prototype.$baseUrl = "http://api.gusiuue.com" + "/api/v1";
}

const service = axios.create({
  baseURL: Vue.prototype.$baseUrl, // url = base url + request url
  headers: {
    "Content-Type": "application/json;",
  },
  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers.Authorization =
      localStorage.getItem("token") == ""
        ? ""
        : "Bearer " + localStorage.getItem("token");
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (parseInt(res.code) !== 0) {
      if (parseInt(res.code) == 401) {
        localStorage.setItem("token", "");
        localStorage.setItem("userInfo", "");
        router.push("/");
      }
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Notification({
      title: "错误",
      message: error.msg || "Error",
      type: "error",
      duration: 2 * 1000,
    });
    return Promise.reject(error);
  }
);
Vue.prototype.$axios = service;
export default service;
