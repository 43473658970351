import Vue from "vue";
import {
    Toast,
    CountDown,
    NoticeBar,
    Popup,
    Swipe,
    SwipeItem,
} from "vant";
Vue.use(CountDown);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(NoticeBar);
