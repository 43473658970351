<template>
  <div id="app">
    <keep-alive>
      <router-view class="router" ref="router"></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  watch: {},
};
</script>
<style lang="scss">
#app {
}
::v-deep .van-popup {
  background: transparent;
}
input {
  autocomplete: off;
}
/* 或者针对特定的浏览器 */
input::-webkit-auto-fill-credential-button {
  display: none !important;
}
@import "assets/css/common";
</style>
