<template>
  <div class="homePage">
    <topHead @loginOut="parentMethod" ref="child" :pageIndex="1"></topHead>
    <mainContent :active-index="0"></mainContent>
    <div class="banner">
      <img src="../../assets/images/home/banner.png" alt="" />
      <div class="bannerFoot">
        <div
          style="
            width: 1200px;
            margin: 0 auto;
            color: #fff;
            font-size: 15px;
            line-height: 40px;
          "
        >
          {{ $t("home.bannerTest") }}
        </div>
      </div>
    </div>
    <div class="main">
      <div class="w12">
        <div class="game1">
          <div class="zhen">
            <div class="zhenTop">
              <div class="title">真人视讯</div>
              <div class="tips">美女主播 热火传情</div>
            </div>
            <div class="zhenBottom">
              <div class="zhenLeft">
                <div class="zhenTitle">AG视讯</div>
                <div class="zhenTips">B003 <span>第31局</span></div>
                <div class="zhenBtn flex_center">快速入座</div>
              </div>
              <div class="zhenRight">
                <div class="shu" v-for="i in 15" :key="i">
                  <div class="heng" v-for="t in 7" :key="t"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cai">
            <div class="caiTop">
              <div class="left"><span>热门</span>彩种推荐</div>
              <div class="right">换一换</div>
            </div>
            <div class="caiBottom">
              <div class="caiItem" v-for="i in 3" :key="i">
                <img class="caiIcon" src="" alt="" />
                <div class="caiTime">00:00:32</div>
                <div class="caiInfo">
                  <div class="title">第09879086期</div>
                  <div class="caiBtn flex_center">快速游戏</div>
                </div>
              </div>
            </div>
          </div>
          <div class="bang">
            <div class="bangName">
              <div class="left">盈利榜</div>
              <div class="right">投注榜</div>
            </div>
            <div class="pai">
              <div class="left">排行/帐号</div>
              <div class="left">盈利(元)</div>
            </div>
            <div class="bangMain">
              <div class="bangItem" v-for="i in 6" :key="i">
                <div class="left">
                  <div class="leftIcon">
                    <div class="No3 flex_center">{{ i }}</div>
                  </div>
                  <div class="name">g1i***3k2</div>
                </div>
                <div class="right">1,642,114.00</div>
              </div>
            </div>
            <div class="bangBottom flex_center">统计时间：今天</div>
          </div>
        </div>
        <div class="game2">
          <div class="left">
            <div class="info">
              <div class="title">棋牌游戏</div>
              <div class="tips">休闲益智 棋乐无穷</div>
            </div>
          </div>
          <div class="right">
            <div class="info">
              <div class="title">彩票游戏</div>
              <div class="tips">超高赔率 分秒精彩</div>
            </div>
          </div>
        </div>
        <div class="game3">
          <div class="dian">
            <div class="infoBox">
              <div class="title">电子游艺</div>
              <div class="tips">超级大奖池</div>
            </div>
          </div>
          <div class="fish">
            <div class="infoBox">
              <div class="title">捕鱼游戏</div>
              <div class="tips">满屏暴金</div>
            </div>
          </div>
          <div class="tiyu">
            <div class="infoBox">
              <div class="title">体育电竞</div>
              <div class="tips">丰富赛事</div>
            </div>
          </div>
        </div>

        <div class="mainBottom">
          <div class="bottomItem">
            <img class="phone" src="../../assets/images/home/img0.png" alt="" />
            <div class="rightBox">
              <div class="title">手机投注</div>
              <div class="tips">业内最顶尖的手机投注APP</div>
            </div>
          </div>
          <div class="bottomItem">
            <img class="down" src="../../assets/images/home/img1.png" alt="" />
            <div class="rightBox">
              <div class="title">下载App</div>
              <div class="tips">防劫持，极致体验</div>
            </div>
          </div>
          <div class="bottomItem">
            <img class="qian" src="../../assets/images/home/img2.png" alt="" />
            <div class="rightBox">
              <div class="title">合作代理</div>
              <div class="tips">日进斗金，月入百万</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogTableVisible"
      width="600px"
      :show-close="false"
      @close="close"
    >
      <div style="text-align: center; margin-bottom: 30px">
        <img
          src="../../assets/images/login/位图@2x.png"
          alt=""
          style="width: 232px; height: 45px"
        />
      </div>
      <div style="padding: 0 70px; box-sizing: border-box">
        <div v-if="model == 0">
          <div class="inputItem">
            <img
              src="../../assets/images/login/我的 (3)@2x.png"
              alt=""
              class="inputIcon"
            />
            <el-input
              autocomplete="new-password"
              v-model="userName"
              placeholder="输入用户名"
            ></el-input>
          </div>
          <div class="inputItem" style="margin-top: 16px">
            <img
              src="../../assets/images/login/密码 (1)@2x.png"
              alt=""
              class="inputIcon"
            />
            <el-input
              autocomplete="new-password"
              placeholder="输入登录密码"
              v-model="passWord"
              show-password
            ></el-input>
          </div>
          <div class="login" @click="login">登录</div>
          <div class="resige" @click="Goresige">去注册</div>
        </div>
        <div v-if="model == 1">
          <div class="inputItem">
            <img
              src="../../assets/images/login/我的 (3)@2x.png"
              alt=""
              class="inputIcon"
            />
            <el-input
              autocomplete="new-password"
              v-model="userName"
              placeholder="输入用户名"
            ></el-input>
          </div>
          <div class="inputItem" style="margin-top: 16px">
            <img
              src="../../assets/images/login/密码 (1)@2x.png"
              alt=""
              class="inputIcon"
            />
            <el-input
              autocomplete="new-password"
              placeholder="输入登录密码"
              v-model="passWord"
              show-password
            ></el-input>
          </div>
          <div class="inputItem" style="margin-top: 16px">
            <img
              src="../../assets/images/login/密码 (1)@2x.png"
              alt=""
              class="inputIcon"
            />
            <el-input
              autocomplete="new-password"
              placeholder="再次输入登录密码"
              v-model="passWord1"
              show-password
            ></el-input>
          </div>
          <div class="login" @click="resige">注册</div>
          <div class="resige" @click="Gologin">登录</div>
        </div>
        <div style="display: flex; align-items: center; margin-top: 18px">
          <img
            src="../../assets/images/login/客服 (5)@2x.png"
            alt=""
            style="width: 28px; height: 29px; margin-right: 11px"
          />
          <span>联系客服</span>
        </div>
        <div style="margin: 31px 0 25px; font-weight: 600">当前线路：269ms</div>
        <div style="display: flex; flex-wrap: wrap">
          <div class="soltItem" style="margin-right: 50px">
            <div class="dost"></div>
            <div>线路1</div>
            <div class="kk">
              <div style="display: flex">
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
              </div>
              <div>170ms</div>
            </div>
          </div>
          <div class="soltItem">
            <div class="dost"></div>
            <div>线路1</div>
            <div class="kk">
              <div style="display: flex">
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
              </div>
              <div>170ms</div>
            </div>
          </div>
          <div class="soltItem" style="margin-right: 50px">
            <div class="dost"></div>
            <div>线路1</div>
            <div class="kk">
              <div style="display: flex">
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
              </div>
              <div>170ms</div>
            </div>
          </div>
          <div class="soltItem">
            <div class="dost"></div>
            <div>线路1</div>
            <div class="kk">
              <div style="display: flex">
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
                <div class="cc"></div>
              </div>
              <div>170ms</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <menuComponent></menuComponent>
    <footerComponent></footerComponent>
  </div>
</template>

<script>
import topHead from "@/components/TopHead.vue";
import mainContent from "@/components/mainContent.vue";
import footerComponent from "@/components/footerComponent.vue";
import menuComponent from "@/components/menuComponent.vue";
export default {
  name: "homeIndex",
  components: {
    topHead,
    mainContent,
    footerComponent,
    menuComponent,
  },
  data() {
    return {
      dialogTableVisible: false,
      userName: "",
      passWord: "",
      passWord1: "",
      model: 0, //0 登录  1注册
      isLogin: false,
      canclick: true,
    };
  },
  computed: {},
  onLoad() {},
  mounted() {
    if (
      localStorage.getItem("token") == "" ||
      localStorage.getItem("token") == null
    ) {
      this.dialogTableVisible = true;
    } else {
      this.getinfo();
    }
  },
  methods: {
    parentMethod() {
      this.dialogTableVisible = true;
    },
    close() {
      this.model = 0;
    },
    Goresige() {
      this.model = 1;
    },
    login() {
      if (this.canclick == false) {
        return;
      } else {
        this.canclick = false;
        this.$axios
          .post(`/auth/login`, {
            username: this.userName,
            password: this.passWord,
          })
          .then(({ data }) => {
            if (data.token) {
              this.$message.success(this.$i18n.t("login.success"));
              this.dialogTableVisible = false;
              localStorage.setItem("token", data.token);
              this.getinfo();
            }
          })
          .catch((error) => {
            console.log(error);
            let msg = error.msg || this.$i18n.t("login.error");
            this.$message.error(msg);
          })
          .finally(() => {
            this.canclick = true;
          });
      }
    },
    resige() {
      if (this.canclick == false) {
        this.$message.success("正在注册中");
      } else {
        this.canclick = false;
        this.$axios
          .post(`/auth/register`, {
            username: this.userName,
            password: this.passWord,
            password_confirmation: this.passWord1,
          })
          .then(({ data }) => {
            this.dialogTableVisible = false;
            this.$message.success(this.$i18n.t("login.success1"));
            localStorage.setItem("token", data.token);
            this.getinfo();
          })
          .catch((error) => {
            console.log(error);
            let msg = error.msg || this.$i18n.t("login.error1");
            this.$message.error(msg);
          })
          .finally(() => {
            this.canclick = true;
          });
      }
    },
    Gologin() {
      this.model = 0;
    },
    getinfo() {
      this.$axios
        .get(`/auth/me`)
        .then(({ data }) => {
          localStorage.setItem("userInfo", JSON.stringify(data));
          this.$refs.child.getNew();
        })
        .catch((error) => {
          console.log(error);
          let msg = error.msg;
          this.$message.error(msg);
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.cc {
  width: 4px;
  height: 17px;
  background: #fe0100;
  border-radius: 2px;
  margin-right: 2px;
}
.kk {
  width: 119px;
  height: 25px;
  background: #f2f2f2;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 6px;
  box-sizing: border-box;
  margin-left: 10px;
}
.soltItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dost {
  width: 6px;
  height: 6px;
  background: #999999;
  border-radius: 50%;
  margin-right: 10px;
}
.login {
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, #436de4 0%, #203bc4 100%);
  border-radius: 9px;
  cursor: pointer;
  margin: 50px 0 16px;
  text-align: center;
  line-height: 50px;
  color: #fff;
}
.resige {
  width: 100%;
  height: 50px;
  border-radius: 9px;
  border: 1px solid #203bc4;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  color: #203bc4;
}
::v-deep .el-dialog__body {
  padding: 0 20px 30px !important;
}

::v-deep .el-input__inner {
  border: none !important;
  font-size: 18px;
}
.inputItem {
  border-bottom: #dcdcdc 1px solid;
  padding: 8px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.inputIcon {
  width: 26px;
  height: 26px;
  margin-right: 11px;
}
.main {
  padding-top: 50px;
  padding-bottom: 9px;
  .game1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
    .zhen {
      width: 498px;
      .zhenTop {
        height: 189px;
        background: url("@/assets/images/home/zhen.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        .title {
          position: absolute;
          top: 14px;
          right: 11px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 30px;
          color: #ffffff;
        }
        .tips {
          position: absolute;
          top: 54px;
          right: 11px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
        }
      }
      .zhenBottom {
        height: 162px;
        background: linear-gradient(180deg, #ffffff 0%, #ddf6fa 100%);
        border-radius: 0px 0px 12px 12px;
        display: flex;
        align-items: center;
        padding: 21px 29px 14px 0;
        box-sizing: border-box;
        .zhenLeft {
          width: 198px;
          height: 100%;
          padding: 7px 0 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          box-sizing: border-box;
          .zhenTitle {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 28px;
            color: #203bc4;
          }
          .zhenTips {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 13px;
            color: #ff5c02;
            span {
              color: #545454;
            }
          }
          .zhenBtn {
            width: 108px;
            height: 35px;
            border-radius: 18px;
            border: 1px solid #203bc4;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #203bc4;
          }
        }
        .zhenRight {
          border-top: 1px solid #c6e4fd;
          border-left: 1px solid #c6e4fd;
          flex: 1;
          height: 100%;
          display: flex;
          .shu {
            flex: 1;
            border-right: 1px solid #c6e4fd;
            display: flex;
            flex-direction: column;
            .heng {
              flex: 1;
              border-bottom: 1px solid #c6e4fd;
            }
          }
        }
      }
    }
    .cai {
      width: 300px;
      .caiTop {
        height: 70px;
        background: url("@/assets/images/home/lotteryBg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 31px;
        .left {
          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 22px;
            color: #ffffff;
          }
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
        }
        .right {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .caiBottom {
        height: 280px;
        background: linear-gradient(180deg, #ffffff 0%, #ddf6fa 100%);
        border-radius: 0px 0px 12px 12px;
        padding: 15px 14px 10px 22px;
        box-sizing: border-box;
        display: flex;

        justify-content: space-between;
        flex-direction: column;
        .caiItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .caiIcon {
            width: 70px;
            height: 70px;
          }
          .caiTime {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
          }
          .caiInfo {
            .title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              text-align: center;
            }
            .caiBtn {
              width: 106px;
              height: 31px;
              border-radius: 16px;
              border: 1px solid #ff7e37;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 15px;
              color: #ff7e37;
              margin-top: 5px;
            }
          }
        }
      }
    }
    .bang {
      width: 302px;
      height: 377px;
      background: url("@/assets/images/home/prizeBg1.png") no-repeat;
      background-size: 100% 100%;
      transform: translate(0, -14px);
      padding-top: 97px;
      box-sizing: border-box;
      .bangName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        .left {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #203bc4;
        }
        .right {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #696969;
        }
      }
      .pai {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 13px;
        margin-top: 14px;
        .left {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 13px;
          color: #696969;
        }
      }
      .bangMain {
        margin-top: 14px;
        height: 178px;
        .bangItem {
          height: 21px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px 0 14px;
          margin-bottom: 8px;
          .left {
            display: flex;
            align-items: center;
            .leftIcon {
              width: 25px;
              height: 20px;
              margin-right: 25px;
              display: flex;
              justify-content: center;
              .No2 {
                width: 23px;
                height: 20px;
              }
              .No3 {
                width: 22px;
                height: 22px;
                background: #50b0e7;
                border-radius: 50%;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 11px;
                color: #ffffff;
              }
            }
            .name {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #696969;
            }
          }
          .right {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 13px;
            color: #ff5c00;
          }
        }
      }
      .bangBottom {
        height: 35px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 13px;
        color: #ffffff;
      }
    }
  }
  .game2 {
    height: 276px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 590px;
      height: 298px;
      margin-top: -6px;
      margin-left: -26px;
      background: url("@/assets/images/home/qiapibg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .info {
        position: absolute;
        right: 25px;
        top: 39px;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 30px;
          color: #ffffff;
          text-align: right;
        }
        .tips {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
          text-align: right;
        }
      }
    }
    .right {
      width: 598px;
      height: 276px;
      margin-right: -34px;
      background: url("@/assets/images/home/lotterylistbg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .info {
        position: absolute;
        left: 26px;
        bottom: 32px;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 30px;
          color: #ffffff;
        }
        .tips {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
        }
      }
    }
  }
  .game3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 230px;
    margin: 9px 0 22px;
    .dian {
      background: url("@/assets/images/home/slotbg.png") no-repeat;
      background-size: 100% 100%;
      width: 434px;
      height: 230px;
      margin-left: -66px;
      position: relative;
    }
    .fish {
      background: url("@/assets/images/home/buyubg.png") no-repeat;
      background-size: 100% 100%;
      width: 382px;
      height: 230px;
      position: relative;
      margin-left: -8px;
    }
    .tiyu {
      background: url("@/assets/images/home/tiyubg.png") no-repeat;
      background-size: 100% 100%;
      width: 367px;
      height: 230px;
      position: relative;
    }
    .infoBox {
      position: absolute;
      right: 19px;
      bottom: 30px;
      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 30px;
        color: #ffffff;
      }
      .tips {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
        text-align: right;
      }
    }
  }
  .mainBottom {
    padding: 0 81px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    background: #ffffff;
    border-radius: 20px;
    .bottomItem {
      display: flex;
      align-items: center;
      .phone {
        width: 31px;
        height: 56px;
      }
      .down {
        width: 50px;
        height: 52px;
      }
      .qian {
        width: 53px;
        height: 55px;
      }
      .rightBox {
        margin-left: 24px;
        .title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 22px;
          color: #696969;
        }
        .tips {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #9a9a9a;
          margin-top: 6px;
        }
      }
    }
  }
}

.banner {
  width: 100%;
  min-width: 1200px;
  position: relative;
  img {
    width: 100%;
  }
  .bannerFoot {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
