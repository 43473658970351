import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home/index.vue";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/videos",
    name: "videos",
    component: () => import("../views/videos/index.vue"),
  },
  {
    path: "/chess",
    name: "chess",
    component: () => import("../views/chess/index.vue"),
  },
  {
    path: "/electron",
    name: "fish",
    component: () => import("../views/electron/index.vue"),
  },
  {
    path: "/sports",
    name: "sports",
    component: () => import("../views/sports/index.vue"),
  },
  {
    path: "/discount",
    name: "discount",
    component: () => import("../views/discount/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/foot/about.vue"),
  },
  {
    path: "/lottery",
    name: "lottery",
    component: () => import("../views/lottery/index.vue"),
  },
  {
    path: "/1fsc",
    name: "1fsc",
    component: () => import("../views/lottery/1fsc.vue"),
  },
  {
    path: "/1fssc",
    name: "1fssc",
    component: () => import("../views/lottery/1fssc.vue"),
  },
  {
    path: "/1flhc",
    name: "1flhc",
    component: () => import("../views/lottery/1flhc.vue"),
  },
  {
    path: "/1fk3",
    name: "1fk3",
    component: () => import("../views/lottery/1fk3.vue"),
  },
  {
    path: "/pc28",
    name: "pc28",
    component: () => import("../views/lottery/pc28.vue"),
  },
  {
    path: "/long",
    name: "long",
    component: () => import("../views/lottery/long.vue"),
  },
  {
    path: "/kl8",
    name: "kl8",
    component: () => import("../views/lottery/kl8.vue"),
  },
  {
    path: "/ft",
    name: "ft",
    component: () => import("../views/lottery/ft.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to,form,next) => {

// })
export default router;
