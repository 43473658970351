import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/request.js";
import "./plugins/vant";
import "vant/lib/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "@/lange/index";
Vue.use(ElementUI);
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
