// i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import zh from "./zh";
Vue.use(VueI18n);

const messages = {
  zh: zh,
};

const i18n = new VueI18n({
  locale: "zh", // set default locale
  messages, // set locale messages
});

export default i18n;

// 切换语言
// i18n.locale = "fr";
