export default {
  login: {
    success: "登录成功",
    error: "登录失败",
    success1: "注册成功",
    error1: "注册失败",
    insuccess: "充值成功，等待后台审核",
  },
  home: {
    账号: "账号",
    余额: "余额",
    一键刷新: "一键刷新",
    vip: "会员中心",
    info: "我的信息",
    登出: "登出",
    shua: "刷新余额成功！",
    xiaoxi: "来自新葡京的消息",
    充值: "充值",
    提现: "提现",
    个人中心: "个人中心",
    退出: "退出",
    代理佣金: "代理佣金",
    赠送: "赠送",
    提款: "提款",
    网银转账: "网银转账",
    存款姓名: "存款姓名",
    保存姓名: "保存姓名",
    存款金额: "存款金额",
    单笔存款金额: "单笔存款金额",
    立即存款: "立即存款",
    存款信息: "存款信息",
    yizhi: "转账金额务必与订单金额一致",
    yuan: "元",
    tuijian: "推荐使用钱包支付",
    取款人: "取款人",
    上传支付凭证: "上传支付凭证",
    取消存款申请: "取消存款申请",
    shangchuan: "上传凭证后即可确认付款",
    我已存款: "我已存款",
    U币支付: "U币支付",
    U币余额: "U币余额",
    danwei: "¥",
    购买U币: "购买U币",
    温馨提示: "温馨提示",
    dengzhi:
      "1U币=1人民币，支持微信、支付宝、银行卡，您可以随时将U币兑换为筹码，",
    info1: "方便快捷秒到账；也可以将筹码提现为U币经交易平台售出转化为法币。",
    info2:
      "充值时候，切记提交订单的金额和充值的金额要相一致，这样成功率较高，1~3分钟即可到账,",
    info3: "如不一致，会导致不到账哦",
    info4: "支付成功不到账的，请找在线客服或推广代理",
    info5:
      "由于银行管制、导致第三方充值通道不稳定、如果充值一次未成功请换其他通道进行充值！",
    K豆钱包: "K豆钱包",
    通道一: "通道一",
    今日: "今日",
    昨天: "昨天",
    最近七天: "最近七天",
    选择时间: "选择时间",
    搜索: "搜索",
    提款编号: "提款编号",
    dizhi: "银行名称/USDT地址",
    提款金额: "提款金额",
    提款时间: "提款时间",
    状态: "状态",
    备注: "备注",
    催账: "催账",
    钱包取款: "钱包取款",
    绑定时间: "绑定时间",
    可提现金额: "可提现金额",
    全额提款: "全额提款",
    资金密码: "资金密码",
    请输入6位资金密码: "请输入6位资金密码",
    zhuyi: "注意：收款地址绑定错误请及时联系客服解绑",
    立即取款: "立即取款",
    钱包: "钱包",
    地址: "地址",
    绑定银行卡: "绑定银行卡",
    提款姓名: "提款姓名",
    开户银行: "开户银行",
    请选择: "请选择",
    银行卡号: "银行卡号",
    开户网点: "开户网点",
    确认提交: "确认提交",
    确认密码: "确认密码",
    选择币种: "选择币种",
    USDT地址: "USDT地址",
    请输入您的USDT收款地址: "请输入您的USDT收款地址",
    绑定三方: "绑定三方",
    请输入您的收款地址: "请输入您的收款地址",
    转账中心: "转账中心",
    中心钱包: "中心钱包",
    锁定钱包: "锁定钱包",
    钱包金额: "钱包金额",
    一键回收: "一键回收",
    DG视讯: "DG视讯",
    收起: "收起",
    自动转账: "自动转账",
    全部金额: "全部金额",
    立即转账: "立即转账",
    本月: "本月",
    上月: "上月",
    计算公式: "计算公式",
    ban: "如需帮助,请",
    联系客服: "联系客服",
    我的资料: "我的资料",
    真实姓名: "真实姓名",
    游戏账号: "游戏账号",
    请输入游戏地址: "请输入游戏地址",
    请输入手机号码: "请输入手机号码",
    手机号: "手机号",
    微信号码: "微信号码",
    请输入微信号: "请输入微信号",
    确认修改: "确认修改",
    投注记录: "投注记录",
    本周: "本周",
    上周: "上周",
    彩票游戏: "彩票游戏",
    时间: "时间",
    笔数: "笔数",
    投注金额: "投注金额",
    输赢: "输赢",
    操作: "操作",
    详情: "详情",
    总投注: "总投注",
    总输赢: "总输赢",
    gong: "共",
    tiao: "条",
    存款时间: "存款时间",
    流水号: "流水号",
    金额: "金额",
    账号安全: "账号安全",
    修改登录密码: "修改登录密码",
    修改资金密码: "修改资金密码",
    旧密码: "旧密码",
    请输入旧密码: "请输入旧密码",
    新密码: "新密码",
    请输入新密码: "请输入新密码",
    请输入确认密码: "请输入确认密码",
    原资金密码: "原资金密码",
    请输入6位原资金密码: "请输入6位原资金密码",
    新资金密码: "新资金密码",
    请输入6位新资金密码: "请输入6位新资金密码",
    请输入6位确认密码: "请输入6位确认密码",
    我的收益: "我的收益",
    今日收益: "今日收益",
    总投注额: "总投注额",
    直属投注额: "直属投注额",
    总分润额: "总分润额",
    直属分额度: "直属分额度",
    总分润: "总分润",
    推广链接: "推广链接",
    复制: "复制",
    精准开户: "精准开户",
    创建邀请链接: "创建邀请链接",
    管理邀请链接: "管理邀请链接",
    开户类型: "开户类型",
    邀请码: "邀请码",
    代理: "代理",
    会员: "会员",
    查看CP返点赔率表: "查看CP返点赔率表",
    开户账号: "开户账号",
    khzh: "6-10位数字或字母",
    默认密码: "默认密码",
    分润设置: "分润设置",
    统一设置: "统一设置",
    全部: "全部",
    邀请链接: "邀请链接",
    生成时间: "生成时间",
    注册人数: "注册人数",
    系统信息: "系统信息",
    guanbi: "是否关闭自动转账功能？",
    取消: "取消",
    确认: "确认",
    确定: "确定",
    来自新葡京的消息: "来自新葡京的消息",
    是否继续该笔充值: "是否继续该笔充值",
    支付: "支付",
    彩票返点赔率表: "彩票返点赔率表",
    抽取点数: "抽取点数",
    计算赔率: "计算赔率",
    游戏玩法: "游戏玩法",
    下级点数: "下级点数",
    下级赔率: "下级赔率",
    代理佣金转账: "代理佣金转账",
    转账金额: "转账金额",
    转账: "转账",
    个人: "个人",
    优惠: "优惠",
    客服: "客服",
    信息: "信息",
    JDpay: "JDpay",
    C币钱包: "C币钱包",
    钱能钱包: "钱能钱包",
    钱包支付: "钱包支付",
    支付宝支付: "支付宝支付",
    VIP充值: "VIP充值",
    提款记录: "提款记录",
    人民币取款: "人民币取款",
    USDT取款: "USDT取款",
    U币取款: "U币取款",
    绑定USDT: "绑定USDT",
    绑定钱包: "绑定钱包",
    个人报表: "个人报表",
    存款记录: "存款记录",
    取款记录: "取款记录",
    优惠记录: "优惠记录",
    代理记录: "代理记录",
    其他记录: "其他记录",
    代理报表: "代理报表",
    下级报表: "下级报表",
    下级开户: "下级开户",
    下级列表: "下级列表",
    下级财务: "下级财务",
    下级人数: "下级人数",
    投诉建议: "投诉建议",
    已发信息: "已发信息",
    会员余额: "会员余额",
    取款金额: "取款金额",
    会员输赢: "会员输赢",
    投资金额: "投资金额",
    优惠金额: "优惠金额",
    团队净盈利: "团队净盈利",
    团队返点: "团队返点",
    团队余额: "团队余额",
    新增用户: "新增用户",
    下级总人数: "下级总人数",
    首存人数: "首存人数",
    投注人数: "投注人数",
    有效投注: "有效投注",
    中奖金额: "中奖金额",
    活动礼金: "活动礼金",
    jiujiu: "988pay",
    bannerTest:
      "使用钱包支付充值的回忆，次日即可自动领取充值优惠，强烈推荐客户充值使用钱包支付！祝亲游戏愉快，盈利多多！",
    可用余额: "可用余额",
    feng: "已封盘",
  },
  tabs: {
    首页: "首页",
    真人视讯: "真人视讯",
    棋牌游戏: "棋牌游戏",
    彩票游戏: "彩票游戏",
    电子游戏: "电子游戏",
    捕鱼游戏: "捕鱼游戏",
    体育电竞: "体育电竞",
    优惠活动: "优惠活动",
    在线客服: "在线客服",
    热门彩种: "热门彩种",
    开奖走势: "开奖走势",
    玩法规则: "玩法规则",
    手机APP下载: "手机APP下载",
  },
  tab: {
    用户名: "用户名",
    类型: "类型",
    投注金额: "投注金额",
    中奖金额: "中奖金额",
    团队返点: "团队返点",
    优惠礼金: "优惠礼金",
    盈利: "盈利",
    请输入: "请输入",
    帐号: "帐号",
    姓名: "姓名",
    用户类型: "用户类型",
    余额: "余额",
    注册时间: "注册时间",
    最后登录: "最后登录",
    状态: "状态",
    时间: "时间",
    总人数: "总人数",
    新增用户: "新增用户",
    存款人数: "存款人数",
    存款金额: "存款金额",
    请输入帐号: "请输入帐号",
    存款方式: "存款方式",
    存款时间: "存款时间",
  },
};
