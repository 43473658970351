<template>
  <div class="titleBox">
    <div class="w12">
      <div class="logoImg">
        <img src="@/assets/images/home/logo.png" alt="" />
      </div>
      <div class="titleContent">
        <div
          class="itemTitle"
          :class="activeIndex == index ? 'active' : ''"
          @click="selectIndex(item.path)"
          v-for="(item, index) in homeList"
          :key="index"
        >
          <div class="zhTitle">
            {{ item.zh }}
          </div>
          <div class="enTitle">
            {{ item.en }}
          </div>
          <div class="itemFoot" v-if="activeIndex == index">
            <img src="@/assets/images/home/active.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mainContent",
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      homeList: [
        { zh: this.$t("tabs.首页"), en: "HOME", path: "/" },
        { zh: this.$t("tabs.真人视讯"), en: "CASINO", path: "/videos" },
        { zh: this.$t("tabs.棋牌游戏"), en: "CHESS", path: "/chess" },
        { zh: this.$t("tabs.彩票游戏"), en: "LOTTERY", path: "/lottery" },
        { zh: this.$t("tabs.电子游戏"), en: "GAME", path: "/electron" },
        { zh: this.$t("tabs.捕鱼游戏"), en: "FISHING", path: "" },
        { zh: this.$t("tabs.体育电竞"), en: "SPORTS", path: "/sports" },
        { zh: this.$t("tabs.优惠活动"), en: "ACTIVETY", path: "/discount" },
        { zh: this.$t("tabs.在线客服"), en: "SERVICE", path: "" },
      ],
    };
  },
  methods: {
    selectIndex(path) {
      if (path != "") {
        if (this.$route.path !== path) {
          this.$router.push({
            path: path,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.titleBox {
  height: 100px;
  background: #ffffff;
  display: flex;
  align-items: center;
  .w12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logoImg {
      img {
        width: 232px;
        height: 49px;
      }
      margin-right: 87px;
    }
    .titleContent {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .itemTitle {
        text-align: center;
        position: relative;
        .zhTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #54689a;
        }
        .enTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #54689a;
        }
        .itemFoot {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: -30px;
          img {
            width: 32px;
            height: 9px;
          }
        }
      }
      .active {
        .zhTitle,
        .enTitle {
          color: #203bc4;
        }
      }
    }
  }
}
</style>
