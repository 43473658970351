<template>
  <div class="rightMenu">
    <div class="rightItem" v-for="(item,index) in rightMenu" :key="index">
      <img :src="item.src" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "menuComponent",
  data(){
    return{
      rightMenu:[
        {src:require('@/assets/images/home/r0.png'),path:''},
        {src:require('@/assets/images/home/r1.png'),path:''},
        {src:require('@/assets/images/home/r2.png'),path:''},
        {src:require('@/assets/images/home/r3.png'),path:''},
        {src:require('@/assets/images/home/r4.png'),path:''},
        {src:require('@/assets/images/home/r5.png'),path:''}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.rightMenu{
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translate(0,-50%);
  .rightItem{
    width: 65px;
    height: 65px;
    margin-bottom: 5px;
  }
}
</style>
